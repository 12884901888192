.splash {
  /* height: calc(100vh - 100px); */
  background-color: #eeeeee;
  height: calc(100vh - 185px);
  width: 100%;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  /* padding: 10%; */
  padding-top: 20vh;
}

.splash h1 {
  color: var(--newColor);
  font-size: 2rem;
  line-height: 3rem;
  padding-bottom: 1rem;
  text-align: center;
}

.splash-card {
  width: 47vw;
  height: 50vh;
  display: flex;
  justify-content: space-between;
  border-radius: 1rem;
  background-color: #ffffff;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
  background: url(../../Images/hotelRoomPhoneSM.jpg) no-repeat;
  background-size: cover;
  background-position: 40% 50%;
}

.splash-card-text {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  align-items: center;
  flex: 1;
  color: var(--newColor);
  padding: 2rem;
  font-size: 1.2rem;
  text-shadow: 10px 10px 10px #ffffff;
  text-align: center;
}

.splash-card-text form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  width: 60%;
}

/* .splash-card-img { */
/* flex: 1; */
/* border: 1px green solid; */

/* border-radius: 0 1rem 1rem 0; */
/* } */

/* .splash-card img {
    height: 100%;
    object-fit: contain;
    border: 2px blue solid;
} */

.splash-card-overlay {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  background-image: linear-gradient(to right, #ffffff 30%, transparent);
  border-radius: 1rem;
}

.splash-card-text form input {
  line-height: 2rem;
  width: 80%;
  min-width: 22ch;
  border-radius: 5px;
  border: 1px solid grey;
  padding-left: 1ch;
}

@media (max-width: 1440px) {
  .splash-card {
    width: 60vw;
    height: 50vh;
  }
}
@media (max-width: 1024px) {
  .splash-card {
    width: 70vw;
    height: 50vh;
  }
  .splash-card-text form input {
    width: 80%;
  }
}
@media (max-width: 768px) {
  .splash h1 {
    font-size: 2rem;
    line-height: 2.5rem;
  }
  .splash-card {
    width: 80vw;
    height: 50vh;
  }
}
@media (max-width: 480px) {
  .splash-card {
    width: 90vw;
    height: 45vh;
  }
  .splash-card-text form input {
    width: 100%;
  }
  .splash-card-text {
    font-size: 1rem;
  }
}
