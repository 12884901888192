footer {
  position: relative;
  bottom: 0;
  width: 100%;
  height: fit-content;
  background-color: var(--newColor);
  color: #ffffff;
  padding: 1rem 3rem;
}

footer ul {
  list-style: none;
  margin-bottom: 1rem;
}

footer ul li {
  margin-top: 1rem;
  font-weight: 500;
}

footer a {
  text-decoration: none;
  color: #ffffff;
}

.footer_rights {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-start;
}

.footer-divider {
  font-size: 1.2rem;
  font-weight: 300;
  margin: 0 1rem;
  transform: translateY(-2px);
}

@media (max-width: 1440px) {
}
@media (max-width: 1024px) {
  .footer_rights span {
    font-size: 0.8rem;
    line-height: 1.2rem;
  }
}
@media (max-width: 768px) {
  .footer_rights {
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
  }
  .footer-divider {
    display: none;
  }
}
@media (max-width: 480px) {
  .footer_rights span {
    font-size: 0.6rem;
    line-height: 1.2rem;
  }
}
