.navbar {
  position: fixed;
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--newColor);
  z-index: 10;
}

.navbar-logo {
  margin-left: 2rem;
  color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.navbar-logo img {
  height: 2.5rem;
  margin-right: 1rem;
  border: 1px solid tomato;
  border-radius: 50%;
}

.navbar ul {
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  width: 40%;
  height: 100%;
  /* border: 1px yellow solid; */
}

.navbar ul li {
  align-self: center;
  /* border: 1px green solid; */
  height: 100%;
  display: flex;
  align-items: center;
}

.navbar a {
  text-decoration: none;
  color: #fff;
}
.navbar a:active {
  color: var(--secColor);
}

.active_nav {
  color: red;
}

.navbar h1 {
  color: #fff;
  font-weight: 300;
}

.navbar a > span:first-of-type {
  font-size: 3.5rem;
  font-weight: 400;
}
.dropbtn {
  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  /* top: 2rem; */
  left: -2rem;
  background-color: #f9f9f9;
  min-width: 8rem;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: var(--secColor);
}
.dropdown-content a:active {
  color: var(--mainColor);
}

.dropdown:hover .dropdown-content {
  display: block;
}

.hamburger {
  color: #ffffff;
  /* padding-right: 1rem; */
  display: none;
}

.list-dropdown-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 1.2rem;
  display: none;
}

.list-dropdown-content a {
  margin-top: 1rem;
}

.burger_list {
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
}

.burger_list li {
  margin-top: 1rem;
  color: yellow;
}

.sub_menu_close {
  display: none;
}
.sub_menu_open {
  display: flex;
  flex-direction: column;
  line-height: 2rem;
  background-color: #ededed;
  padding: 0.5rem 0;
}

@media (max-width: 1440px) {
  .navbar ul {
    width: 50%;
  }
}
@media (max-width: 1024px) {
  .navbar ul {
    width: 60%;
  }
  .navbar ul li {
    font-weight: 400;
  }
  .navbar h1 span {
    font-size: 2.5rem;
  }

  .hamburger {
    display: none;
  }
}
@media (max-width: 768px) {
  .navbar {
    height: 80px;
  }
  .navbar ul {
    display: none;
  }
  .navbar ul li {
    font-weight: 300;
    font-size: 0.8rem;
  }
  .navbar h1 {
    font-weight: 300;
  }

  .navbar h1 span {
    font-size: 2rem;
  }

  .hamburger {
    color: #ffffff;
    padding-right: 1rem;
    display: block;
  }

  .hamburger i {
    color: #ffffff;
  }
  .burger_list {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
    list-style: none;
    font-size: 1.2rem;
    height: 40vh;
    width: 100%;
  }
  .burger_list li a {
    text-decoration: none;
    color: var(--newColor);
  }
}

@media (max-width: 480px) {
  .navbar h1 {
    font-size: 1.1rem;
  }
}
