.unsubscribe {
  background-color: #eeeeee;
  height: calc(100vh - 185px);
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 20vh;
}

.unsubscribe h1 {
  color: var(--newColor);
  font-size: 3rem;
  line-height: 3rem;
  padding-bottom: 1rem;
  text-align: center;
}

.unsubscribe-card {
  width: 47vw;
  height: 50vh;
  display: flex;
  justify-content: space-between;
  border-radius: 1rem;
  background-color: #ffffff;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
  background: url(../../Images/phone.jpg) center no-repeat;
  background-size: contain;
  background-position: right;
}

.unsubscribe-card-text {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  color: var(--newColor);
  padding: 2rem;
  font-size: 1.2rem;
  text-align: center;
}

.unsubscribe-card-text .card-left {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
}

.unsubscribe-card-text img {
  height: 80px;
  width: 80px;
  margin-bottom: 1rem;
}

.unsubscribe-card-text .welcome-text {
  width: 60%;
}

.unsubscribe-card-text form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  width: 60%;
}

.unsubscribe-card-overlay {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  background-image: linear-gradient(to right, #ffffff 25%, transparent);
  border-radius: 1rem;
}

.unsubscribe-card-text form input {
  line-height: 2rem;
  width: 100%;
  min-width: 22ch;
  border-radius: 5px;
  border: 1px solid grey;
  padding-left: 1ch;
}

@media (max-width: 1440px) {
  .unsubscribe-card {
    width: 60vw;
    height: 50vh;
  }
}
@media (max-width: 1024px) {
  .unsubscribe h1 {
    font-size: 2rem;
  }
  .unsubscribe-card {
    width: 70vw;
    height: 50vh;
  }
  .unsubscribe-card-text form input {
    width: 80%;
  }
}
@media (max-width: 768px) {
  .unsubscribe-card {
    width: 80vw;
    height: 50vh;
  }
}
@media (max-width: 480px) {
  .unsubscribe-card {
    width: 90vw;
    height: 45vh;
  }
  .unsubscribe-card-text form input {
    width: 100%;
  }
}
