.about_hero {
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  background: url(../../Images/hotelRooms.jpg) center no-repeat;
  background-size: cover;
  background-position: center;
  color: #ffffff;
}

/* .blue_overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(146, 203, 226, 0.6);
} */

.darkblue_overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(7, 81, 108, 0.6);
}

.about_hero .darkblue_overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
}

.about_hero h1 {
  font-size: 3rem;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.6);
  text-align: center;
  padding: 2rem;
  width: 90%;
  max-width: 700px;
}

.about_main {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 4rem 25%; */
  color: var(--mainColor);
}

.about_main_top {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  gap: 1rem;
  font-size: 1.2rem;
}

.about_main_top h1 {
  font-size: 3rem;
  font-weight: 400;
}

.about_main_top p {
  margin-top: 1.4rem;
  line-height: 1.8rem;
  /* text-align: justify; */
  width: 100%;
  max-width: 700px;
}

.about_main_middle,
.about_main_bottom {
  display: flex;
  width: 100%;
  height: 40vh;
  overflow: hidden;
  /* border: 1px orangered solid; */
  margin-top: 1rem;
  padding: 0 15%;
}

.about_middle_text,
.about_bottom_text {
  flex: 1;
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  justify-content: center;
  gap: 1rem;
  align-items: center;
  text-align: center;
}

.about_middle_text h2 {
  font-size: 2rem;
  font-weight: 400;
  width: 90%;
}
.about_middle_text p {
  padding-top: 1rem;
  font-style: italic;
}

.about_middle_img {
  flex: 1;
  background: url(../../Images/teamwork.jpg) center no-repeat;
  background-size: cover;
}

.about_bottom_img {
  flex: 1;
  background: url(../../Images/setsapart.jpg) center no-repeat;
  background-size: cover;
  color: #fff;
}

.about_bottom_img .darkblue_overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-size: 1rem; */
}

.about_bottom_img .darkblue_overlay h1 {
  font-weight: 400;
  line-height: 4rem;
  font-size: 3rem;
}

.about_bottom_text p {
  font-size: 1.2rem;
  line-height: 1.7rem;
  padding: 2rem;
}

.about_main_bottom {
  padding-bottom: 2rem;
  flex-direction: row-reverse;
}

.learn-more {
  display: flex;
  /* padding: 2rem 20%; */
  width: 100%;
  max-width: 1200px;
  padding-bottom: 2rem;
}

.learn-more h3 {
  padding-bottom: 1rem;
}

.learn-more-form {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.learn-more-form input {
  line-height: 2rem;
  width: 80%;
  min-width: 22ch;
  border-radius: 5px;
  border: 1px solid grey;
  padding-left: 1ch;
}

.learn-more-text {
  font-size: 1.2rem;
  line-height: 1.8rem;
  flex: 1;
  /* padding-left: 1rem; */
  /* text-align: justify; */
  max-width: 700px;
}

.about_main h1 {
  font-size: 3rem;
  font-weight: 400;
  color: var(--newColor);
  text-align: center;
  padding: 0 1rem;
}

@media (max-width: 1440px) {
  .about_main_top {
    padding: 2rem 10%;
  }
  .about_bottom_img .darkblue_overlay h1 {
    line-height: 3rem;
    font-size: 2.5rem;
  }
  .learn-more {
    padding: 2rem 10%;
  }
  .about_main h1 {
    font-size: 2.5rem;
  }
}
@media (max-width: 1024px) {
  .about_hero h1 {
    font-size: 3rem;
  }
  .about_main_top h1 {
    font-size: 2.5rem;
  }

  .about_main_top p {
    margin-top: 1rem;
    font-size: 1rem;
    line-height: 1.5rem;
    /* text-align: justify; */
  }

  .about_middle_text,
  .about_bottom_text {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    padding: 0 10%;
  }

  .about_middle_text h2 {
    font-size: 1.7rem;
    font-weight: 400;
  }

  .about_bottom_img .darkblue_overlay h1 {
    line-height: 3rem;
    font-size: 2rem;
  }
  .about_bottom_img .darkblue_overlay {
    padding: 2rem;
    text-align: center;
  }
  .about_bottom_text p {
    font-size: 1.2rem;
    line-height: 1.5rem;
    padding: 1rem;
  }
  .about_main h1 {
    font-size: 2.5rem;
  }
}
@media (max-width: 768px) {
  .about_hero {
    height: 40vh;
  }
  .about_hero h1 {
    width: 100%;
    font-size: 2rem;
  }

  .about_hero .darkblue_overlay {
    justify-content: center;
    padding-top: 3rem;
  }
  /* .about_bottom_img .darkblue_overlay {
    flex-wrap: wrap;
    padding: 1rem;
    text-align: center;
  } */

  .about_middle_text h2 {
    font-size: 1.4rem;
    padding-top: 1rem;
    font-weight: 400;
  }
  .about_bottom_text p {
    font-size: 1rem;
    line-height: 1.2rem;
    padding: 0.5rem;
  }

  .about_main_middle,
  .about_main_bottom {
    flex-direction: column-reverse;
    padding: 0 10%;
  }

  .about_main_bottom {
    flex-direction: column-reverse;
    padding-top: 1rem;
    padding: 0 10%;
  }
  .about_main h1 {
    font-size: 2rem;
  }
  .learn-more-text {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
@media (max-width: 480px) {
  .about_hero h1 {
    font-size: 1.6rem;
    padding: 1rem;
  }
  .about_main_top p {
    font-size: 1rem;
    line-height: 1.2rem;
  }

  .about_bottom_text p {
    font-size: 1rem;
    line-height: 1.2rem;
    padding: 1rem;
  }

  .about_middle_text h2 {
    font-size: 1.2rem;
    font-weight: 400;
  }

  .about_main_middle,
  .about_main_bottom {
    padding: 0 5%;
  }
  .learn-more {
    padding: 2rem 5%;
  }
  .about_main h1 {
    font-size: 1.5rem;
  }
}
