.retail_hero {
    background: url("../../Images/retail.jpg") center no-repeat;
    background-size: cover;
    width: 100%;
    height: 70vh;
}

.retail_hero .blue_overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    align-items: center;
}

.retail_hero h1 {
    font-size: 4rem;
    color: #ffffff;
    text-align: center;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.6);
}

.retail_hero p {
    text-align: center;
    color: #ffffff;
    padding: 1rem;
}

.retail_main {
    padding-bottom: 3rem;
}

.retail_card {
    padding: 3rem;
    min-height: 30vh;
    width: 70%;
    color: var(--mainColor);
}

.retail_card h2 {
    text-align: center;
}
.retail_card p {
    text-align: center;
    font-size: 1.1rem;
}

.retail_card_img {
    flex: 1;
    background: url("../../Images/shopping.jpg") center no-repeat;
    background-size: cover;
}

.retail_card_text {
    background-color: #f8f8f8;
    line-height: 1.5rem;

    flex: 2;
}

.retail_checks {
    width: 100%;
    flex-wrap: wrap;
    padding-bottom: 2rem;
    /* border: violet 1px solid; */
    justify-content: center;
    padding-left: 20%;
}

.retail_check_box {
    /* align-items: center;
    justify-content: center; */
    flex-basis: 50%;
    padding-top: 1rem;
    /* border: 1px blue solid; */
}

.retail_check_box span {
    font-weight: 500;
}

.retail_check {
    color: tomato;
    /* border: 1px solid green; */
    padding-right: 1rem;
}

.features_modal {
    width: 60vw;
    flex-wrap: wrap;
    padding: 2rem;
}

.features_modal_bottom h1 {
    padding: 1rem 4rem;
    font-weight: 400;
}

.features_modal_item {
    padding-bottom: 1rem;
    flex-basis: 25%;
    color: var(--secColor);
}

.features_modal_item span {
    padding: 0.15rem 0 0 1rem;
    color: var(--mainColor);
}

.retail_integrations_modal {
    padding: 0 20%;
}

.retail_bottom {
    padding: 2rem 20%;
}

.retail_bottom_card {
    min-height: 30vh;
}

.retail_bottom_img {
    flex: 1;
    background: url("../../Images/business.jpg") center no-repeat;
    background-size: cover;
}

.retail_bottom_text {
    flex: 2;
    /* padding: 1rem 2rem; */
    color: var(--mainColor);
    background-color: #f8f8f8;
}

.retail_bottom_text h1 {
    text-align: center;
    font-weight: 400;
}

.retail_bottom_text h1 span {
    color: tomato;
    font-weight: 500;
}

.retail_bottom_text p {
    font-size: 1.1rem;
    line-height: 1.5rem;
    padding: 1rem;
}

.pad2_20 {
    padding: 2rem 20%;
}

.retail_contact {
    padding-bottom: 3rem;
}

@media (max-width: 1440px) {
    .retail_bottom {
        padding: 2rem 10%;
    }
    .retail_checks {
        padding-left: 10%;
    }
    .retail_integrations_modal {
        padding: 0 10%;
    }
    .retail_card {
        width: 80%;
    }
}
@media (max-width: 1024px) {
    .retail_hero h1 {
        font-size: 3rem;
    }
    .retail_checks {
        padding-left: 0;
    }
    .retail_integrations_modal {
        padding: 0 5%;
    }
    .retail_card {
        width: 90%;
    }
}
@media (max-width: 768px) {
    .retail_hero {
        height: 40vh;
    }
    .retail_hero h1 {
        font-size: 2.5rem;
    }

    .retail_hero .blue_overlay {
        justify-content: flex-end;
        padding-bottom: 2rem;
    }
    .retail_card {
        flex-direction: column;
        width: 90%;
    }
    /* .retail_card p {
        text-align: center;
        font-size: 1.1rem;
    } */
    .retail_card_img {
        min-height: 30vh;
    }
    .retail_check_box {
        flex-basis: 50%;
    }
    .retail_checks {
        padding-left: 5%;
    }
    .retail_bottom_text h1 {
        font-size: 1.5rem;
    }
    .retail_bottom_text p {
        line-height: 1rem;
        line-height: 1.4rem;
    }
    .retail_bottom_card {
        flex-direction: column;
    }
    .retail_bottom_img {
        min-height: 30vh;
    }
}
@media (max-width: 480px) {
    .retail_check_box {
        flex-basis: 100%;
        padding: 0;
    }
    .retail_checks {
        padding-left: 20%;
    }
    .retail_bottom_text h1 {
        font-size: 1.5rem;
    }
    .retail_bottom_text p {
        font-size: 1rem;
        line-height: 1.2rem;
    }
    .retail_card {
        width: 100%;
    }
}
