.form_container {
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
    padding: 1em;
}

.form_ ul {
    list-style: none;
    padding: 0;
}

.brand {
    text-align: center;
}

.brand span {
    color: #fff;
}

.wrapper {
    box-shadow: 0 0 20px 0 rgba(72, 94, 116, 0.7);
}

.wrapper > * {
    padding: 1em;
}

.company-info {
    background: #c9e6ff;
}

.company-info h3,
.company-info ul {
    text-align: center;
    margin: 0 0 1rem 0;
}

.form_contact {
    background: #f9feff;
}

/* FORM STYLES */
.form_contact form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

.form_contact form label {
    display: block;
}

.form_contact form p {
    margin: 0;
}

.form_contact form .full {
    grid-column: 1 / 3;
}

.form_contact form button,
.form_contact form input,
.form_contact form textarea {
    width: 100%;
    padding: 1em;
    border: 1px solid #c9e6ff;
}

.form_contact form button {
    background: #c9e6ff;
    border: 0;
    text-transform: uppercase;
}

.form_contact form button:hover,
.form_contact form button:focus {
    background: #92bde7;
    color: #fff;
    outline: 0;
    transition: background-color 2s ease-out;
}

/* LARGE SCREENS */
@media (min-width: 700px) {
    .wrapper {
        display: grid;
        grid-template-columns: 1fr 2fr;
    }

    .wrapper > * {
        padding: 2em;
    }

    .company-info h3,
    .company-info ul,
    .brand {
        text-align: left;
    }
}
