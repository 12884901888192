.corporate_hero {
    background: url("../../Images/corporate.jpg") center no-repeat;
    background-size: cover;
    width: 100%;
    height: 70vh;
    margin-bottom: 2rem;
}

.corporate_hero .blue_overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    align-items: center;
}

.corporate_hero h1 {
    text-align: center;
    font-size: 4rem;
    color: #ffffff;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.6);
}

.corporate_hero p {
    padding: 1rem;
    text-align: center;
    color: #ffffff;
}

.corporate_main {
    padding: 1rem 10%;
}

.corporate_main .divider {
    padding: 1rem 0;
}

.corporate_main_card {
    flex-direction: row-reverse;
}
.corporate_main_card_img {
    background-color: #ffffff;
    flex: 1;
    /* flex-basis: 35%; */
    /* border: springgreen 1px solid; */
}

.corporate_main_card_text {
    /* background-color: #f8f8f8; */
    font-size: 1.4rem;
    text-align: center;
    color: var(--mainColor);
    padding: 2rem;
    flex: 2;
}

.corporate_main_card_text span {
    font-weight: 500;
}

.corporate_main_card_text p:last-of-type {
    font-size: 1.2rem;
    margin-top: 2rem;
    font-weight: 300;
}

.corporate_main_card_img img {
    background-color: #ffffff;
    /* object-position: center; */
    object-fit: contain;
    width: 100%;
    padding: 2rem;
}

.corporate_middle {
    padding: 2rem 0;
}

.corporate_info_card {
    flex: 1;
    color: var(--mainColor);
}

.corporate_card {
    min-height: 40vh;
}

.corporate_card_img {
    flex: 1;
    background: url("../../Images/employee.jpg") no-repeat;
    background-position: 50%;
    background-size: cover;
}

.corporate_card_text {
    background-color: #f8f8f8;
    font-size: 1.1rem;
    line-height: 1.3rem;
    height: 100%;
}

.corporate_card_text h1 {
    text-align: center;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2rem;
    padding-bottom: 2rem;
}

.corporate_checks {
    flex-wrap: wrap;
    justify-content: center;
}

.corporate_check_box {
    padding: 0.5rem;
    flex-basis: 50%;
    align-items: center;
}
.corporate_check {
    margin-right: 1rem;
    color: tomato;
}

@media (max-width: 1440px) {
    .corporate_main {
        padding: 1rem 10%;
    }
    .corporate_check_box {
        font-size: 1rem;
    }
}

@media (max-width: 1024px) {
    .corporate_hero h1 {
        font-size: 3rem;
    }
    .corporate_main_card_text {
        font-size: 1.2rem;
        padding: 2rem;
        flex: 2;
    }
    .corporate_check_box {
        flex-basis: 100%;
    }
}
@media (max-width: 768px) {
    .corporate_hero {
        height: 40vh;
    }
    .corporate_hero h1 {
        font-size: 2.5rem;
    }

    .corporate_hero .blue_overlay {
        justify-content: flex-end;
        padding-bottom: 2rem;
    }
    .corporate_main {
        padding: 1rem 10%;
    }
    .corporate_main_card {
        flex-direction: column;
    }
    .corporate_main_card_img {
        align-self: center;
        width: 60%;
    }
    .corporate_card {
        flex-direction: column-reverse;
    }
    .corporate_card_text h1 {
        font-size: 1.3rem;
        line-height: 1.5rem;
    }
    .corporate_check_box {
        flex-basis: 50%;
    }
    .corporate_card_img {
        min-height: 40vh;
    }
    .corporate_middle {
        padding: 0;
    }
    .corporate_main_card_text {
        font-size: 1.1rem;
    }
    .corporate_main_card_text p:last-of-type {
        font-size: 1rem;
    }
}
@media (max-width: 480px) {
    .corporate_main_card_text {
        font-size: 1rem;
        padding: 1rem;
    }

    .corporate_main_card_text p:last-of-type {
        font-size: 0.9rem;
    }

    .corporate_check_box {
        flex-basis: 100%;
        font-size: 0.9rem;
    }
}
