.info-compare-container {
  box-sizing: border-box;
  /* padding-top: 2rem; */
  width: 90%;
  max-width: 1200px;
  padding-bottom: 2rem;
}

.info-compare {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.info-compare h2 {
  font-size: 2rem;
  color: var(--mainColor);
  margin-bottom: 1rem;
}

@media (max-width: 1440px) {
}
@media (max-width: 1024px) {
  .info-compare {
    width: 80vw;
  }
}
@media (max-width: 768px) {
  .info-compare {
    width: 90vw;
  }
  .info-compare h2 {
    font-size: 1.6rem;
    font-weight: 500;
  }
}
@media (max-width: 480px) {
}
