.info-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 4rem;
  color: var(--newColor);
  font-size: 18px;
  line-height: 1.5rem;
}

.info-container h2 {
  font-size: 2rem;
  font-weight: 400;
  line-height: 2.4rem;
  text-align: center;
  color: var(--newColor);
}

.info-video {
  width: 80vw;
  height: 63vh;
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  background-color: #ffffff;
}

.info-container .infos {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 2rem 20%;
}

.infos .info-card {
  flex: 1;
  padding: 1rem;
  display: grid;
  grid-template-rows: 1fr 2fr 0.3fr;
  border-radius: 10px;
}

.info-card h2 {
  font-size: 2rem;
  text-align: center;
  line-height: 2.2rem;
  margin-bottom: 1rem;
}

/* .info-card:first-of-type h2 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 30%;
} */

.info-card p {
  margin-bottom: 2rem;
  /* text-align: justify; */
}

.info-card p span {
  font-weight: 600;
}

.card-img {
  border-radius: 50%;
  width: 25%;
  /* height: 300px; */
}

.info-solutions {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 20%;
  background-color: #f8f8f8;
  color: #f8f8f8;
}

.info-solutions h2 {
  font-size: 2rem;
  font-weight: 400;
}

.info-solutions p {
  margin-top: 2rem;
  color: var(--newColor);
}

.industries-btns {
  display: flex;
  gap: 1rem;
  width: 100%;
  padding: 2rem 1rem 0 1rem;
}

.industry-btn {
  flex: 1;
  flex-basis: 20%;
  height: 3rem;
  /* border: 2px solid var(--mainColor); */
  border-radius: 0.3rem;
  padding: 0.8rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 400;
  background-color: var(--mainColor);
  margin: 0.5rem;
  cursor: pointer;
}

.industry-btn:hover {
  background-color: var(--newColor);
}

.industry-btn a {
  color: #f8f8f8;
  text-decoration: none;
  text-transform: uppercase;
  margin-left: 1rem;
}

.info-customize {
  padding: 1rem 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.divider {
  width: 50%;
  border-bottom: 1px solid #eeeeee;
  margin: 2rem;
}

.info-customize h2,
.info-how h2,
.info-compare h2 {
  font-size: 2rem;
  font-weight: 400;
  line-height: 2.4rem;
  text-align: center;
}

.info-customize ul {
  margin-top: 3rem;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding-left: 15%;
}

.info-customize ul li {
  flex: 1;
  flex-basis: 40%;
  font-size: 1.5rem;
  padding: 1rem;
}

.info-customize ul li {
  display: flex;
  align-items: center;
}

.info-how {
  padding: 0 20% 4rem 20%;
}

.info-how-cards {
  display: flex;
  /* gap: 1rem; */
  justify-content: space-between;
  padding-top: 2rem;
}

.how-card {
  /* flex: 1; */
  flex-basis: 31%;
  padding: 2rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
  background-color: #f8f8f8;
  /* box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.4); */
}

.how-card h3 {
  text-align: center;
  margin-bottom: 1rem;
}

.how-card p {
  margin-bottom: 1rem;
  /* text-align: justify; */
}

.how-card p span {
  font-weight: 500;
}

.info_check {
  color: var(--mainColor);
  padding-right: 1rem;
  padding-top: 0.15rem;
}
.orange_check {
  color: tomato;
  padding-right: 1rem;
  padding-top: 0.15rem;
}

.info-compare {
  /* padding: 0 10% 3rem 10%; */
  width: 100%;
}

.info-compare h1 {
  padding-bottom: 2rem;
}

.info-compare p {
  font-weight: 400;
  margin-bottom: 2rem;
  text-align: center;
}

.info-table-container {
  width: 100%;
  /* border: red 1px solid; */
}

.info-table {
  color: var(--mainColor);
}

.info_btn {
  background-color: var(--mainColor);
  /* color: #ffffff; */
  color: red;
}

.customize-item:last-of-type {
  color: tomato;
}
@media (max-width: 1440px) {
  .info-card h2 {
    line-height: 2rem;
    margin-bottom: 1rem;
  }
  .info-container .infos {
    padding: 2rem 10%;
  }
  .info-card:first-of-type h2 {
    padding: 0 35%;
    font-weight: 500;
  }
  .info-card:last-of-type h2 {
    font-weight: 500;
    padding: 0 9%;
  }
  .info-solutions {
    padding: 2rem 10%;
  }

  .info-customize {
    padding: 2rem 10%;
  }

  .info-customize ul {
    margin-top: 2rem;
    padding-left: 20%;
  }

  .info-customize ul li {
    flex: 1;
    flex-basis: 40%;
    font-size: 1.3rem;
    padding: 1rem;
  }

  .info-how {
    padding: 0 10% 2rem 10%;
  }

  .how-card {
    /* flex: 1; */
    flex-basis: 31%;
    padding: 2rem;
    border-radius: 0.5rem;
    background-color: #f8f8f8;
    /* box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.4); */
  }

  .how-card p {
    text-align: left;
    font-size: 1rem;
    line-height: 1.3rem;
  }
  .how-card p:nth-last-of-type(1) {
    margin-bottom: 0;
  }

  .info-compare {
    padding: 0 10% 3rem 10%;
  }
}
@media (max-width: 1024px) {
  .info-container .infos {
    padding: 2rem 5%;
  }

  .infos .info-card {
    flex: 1;
  }

  .info-container h1 {
    font-size: 2.5rem;
  }
  .info-video {
    width: 100%;
    max-height: 100%;
    margin-top: 4rem;
    padding: 0 10%;
  }
  .info-card h2 {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 1rem;
  }
  .info-card p {
    font-size: 1rem;
  }
  .info-card:first-of-type h2 {
    padding: 0 32%;
    font-weight: 500;
  }
  .info-card:last-of-type h2 {
    font-weight: 500;
    padding: 0;
  }
  .info-container .infos {
    flex-direction: row;
  }

  .info-customize h1,
  .info-how h1,
  .info-compare h1,
  .info-solutions h1 {
    font-size: 2.5rem;
  }

  .industries-btns {
    flex-wrap: wrap;
  }

  .industry-btn {
    flex-basis: 40%;
  }
  .info-customize {
    padding: 2rem 10%;
  }

  .info-customize ul {
    margin-top: 1rem;
    padding-left: 15%;
  }

  .info-customize h1 {
    font-size: 1.9rem;
    line-height: 2.5rem;
  }

  .info-how {
    padding: 0 5% 2rem 5%;
  }

  .how-card {
    flex-basis: 32%;
    padding: 1rem;
  }
}
@media (max-width: 768px) {
  .info-card:first-of-type h2 {
    padding: 0;
    font-weight: 500;
  }
  .info-container .infos {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 2rem;
  }

  .info-solutions p {
    text-align: center;
    font-size: 1rem;
  }

  .info-customize h1,
  .info-how h1,
  .info-compare h1,
  .info-solutions h1 {
    font-size: 1.8rem;
    line-height: 2.5rem;
  }

  .info-customize {
    padding: 1rem 5%;
  }

  .info-customize ul {
    margin-top: 2rem;
    padding-left: 5%;
  }

  .info-customize ul li {
    /* flex: 1; */
    flex-basis: 30%;
    font-size: 1.1rem;
    padding: 1rem;
    /* border: 1px blue solid; */
  }

  .info-how-cards {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: space-between;
    padding-top: 2rem;
  }

  .how-card h3 {
    font-weight: 500;
  }
  /* 
    .how-card p {
        font-size: 1rem;
    } */
  .info-compare {
    padding: 0 5% 1rem 5%;
  }
}
@media (max-width: 480px) {
  .info-video {
    height: 30vh;
    margin-top: 2rem;
  }
  .industry-btn {
    flex-basis: 80%;
    margin: 0.5rem;
  }

  .how-card {
    flex-basis: 80%;
    margin: 0.5rem;
  }
}
