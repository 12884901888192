.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact_hero {
  height: 70vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background: url(../../Images/hanged_phone.jpg) center no-repeat;
  background-size: cover;
  background-position: 50% 0%;
}

.contact_hero .darkblue_overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
}

.contact_hero h1 {
  font-size: 3rem;
  text-align: center;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.6);
  padding: 2rem;
  width: 60%;
}

.contact_details {
  display: flex;
  flex-direction: column;
  padding: 4rem 20%;
  text-align: center;
  color: var(--mainColor);
}

.contact_details p {
  font-size: 1.2rem;
  line-height: 1.5rem;
  padding: 0 10%;
}

.contact_contacts {
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 2rem;
  font-size: 1.3rem;
}

.contact_contacts h4 span {
  font-size: 1.1rem;
  font-weight: 400;
}

.contact_form {
  display: flex;
  justify-content: center;
  padding-bottom: 3rem;
  /* border: 1px solid rebeccapurple; */
}

/* .contact_form_item {
    width: 40%;
} */

.contact_form_btn {
  padding: 1rem;
  width: 100%;
}
/* 
.Contact_message {
    width: 100%;
} */

.contact_textarea {
  padding: 1rem;
  /* width: 750px; */
  /* border: 1px red solid; */
}

.form_field {
  flex-basis: 48%;
}

.form_error {
  font-size: 14px;
  color: red;
  padding-left: 1rem;
  width: 100%;
  text-align: left;
  padding: 0.2rem 3rem;
}
.form_error_learn {
  font-size: 14px;
  color: red;
  padding-left: 1rem;
  width: 100%;
  text-align: left;
  padding: 0.2rem;
}

.pad {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  /* border: 1px red solid; */
  width: 750px;
}
/* @media (min-width: 1441px) {
} */
@media (max-width: 1440px) {
  .contact_form {
    padding: 0 10% 2rem 10%;
  }
}
@media (max-width: 1024px) {
  .contact_hero h1 {
    font-size: 2.5rem;
  }
  .contact_details {
    padding: 3rem 10%;
  }
  .contact_form {
    padding: 0 5% 1rem 5%;
  }

  .pad {
    width: 650px;
  }
}
@media (max-width: 768px) {
  .contact_hero {
    height: 40vh;
  }
  .contact_hero h1 {
    width: 100%;
    font-size: 2rem;
  }

  .contact_hero .darkblue_overlay {
    justify-content: center;
    padding-top: 3rem;
  }
  .contact_details {
    padding: 3rem 5%;
  }

  .contact_details p {
    font-size: 1rem;
    line-height: 1.2rem;
    padding: 0 5%;
  }

  .contact_contacts {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    font-size: 1.1rem;
    font-weight: 400;
  }

  .pad {
    width: 550px;
  }
}

@media (max-width: 480px) {
  .form_field {
    flex-basis: 100%;
    padding-bottom: 1rem;
  }
  .pad {
    width: 380px;
    flex-direction: column;
    align-content: space-between;
    height: 180px;
  }
}
