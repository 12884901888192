.landing {
  /* height: calc(100vh - 100px); */
  height: 100vh;
  width: 100%;
  background: url(../../Images/Home_banner.jpg) no-repeat;
  background-size: cover;
  background-position: top;
  display: flex;
  align-items: center;
  background-color: var(--newColor);
}

.landing .overlay {
  width: 100%;
  height: 100%;
  padding: 10%;
  background-image: linear-gradient(to right, var(--newColor), transparent);
  display: flex;
  align-items: center;
}

.landing h1 {
  color: #fff;
  line-height: 3rem;
}

.landing .welcome {
  /* position: fixed; */
  width: 30rem;
  color: #fff;
  font-size: 1.2rem;
  line-height: 2rem;
  /* border: 1px red solid; */
}

.landing .welcome-text {
  font-weight: 300;
  margin-bottom: 2rem;
  margin-top: 1rem;
}
@media (max-width: 1440px) {
}
@media (max-width: 1024px) {
  .landing {
    background-position: 80%;
  }
  .landing .welcome {
    align-self: flex-end;
    padding-top: 4rem;
  }
}
@media (max-width: 768px) {
  .landing {
    background-position: 70%;
    /* opacity: 0.8; */
  }

  .landing h1 {
    font-size: 2rem;
    font-weight: 400;
  }

  .landing .welcome {
    max-width: 80%;
    align-self: flex-end;
    padding-bottom: 4rem;
  }

  .landing .welcome-text {
    font-size: 1rem;
  }
}
@media (max-width: 480px) {
  /* .landing {
        background-position: 60%;
    } */
  .landing h1 {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
  .landing .welcome {
    max-width: 100%;
    align-self: flex-end;
    padding-bottom: 5rem;
  }
}
