.privacy-container {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  padding: 7rem;
  width: 100%;
}
.privacy-container > p {
  padding: 1rem 0;
  justify-self: flex-start;
  width: 100%;
}

.privacy-container h1 {
  color: var(--newColor);
  padding-bottom: 2rem;
}

.text-pad {
  padding: 0.5rem 2rem;
}

.text-pad ul {
  padding: 0.3rem 2rem;
}
.text-pad ul li {
  list-style: lower-alpha;
  padding: 0.3rem 1rem;
}
.text-pad ul li ul li {
  list-style: lower-roman;
}
.text-pad span {
  display: block;
}
.text-pad .list-none {
  list-style: none;
}

.text-pad ul li p {
  padding: 0.3rem 0;
}

@media (max-width: 480px) {
  .privacy-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
