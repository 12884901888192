.integrations {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.integrations h1 {
    font-weight: 400;
    color: var(--mainColor);
}

.integrations h1 span {
    font-weight: 600;
}

.integrations_card {
    /* height: fit-content; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: center;
    padding: 1rem 1rem;
    /* border: green 1px solid; */
    min-height: 30vh;
}

.integrations_card p {
    color: var(--mainColor);
}

.inner_integrations {
    display: flex;
    justify-content: center;
    align-items: center;
}

.integrations_card h2 {
    padding: 2rem;
    font-weight: 400;
    color: var(--secColor);
    text-align: center;
    flex: 1;
}

.integrations_card h2 span {
    color: var(--mainColor);
    font-weight: 500;
}

.integrations_imgs {
    flex: 2;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}

.integrations_imgs img {
    width: 25%;
    margin-right: 1rem;
}

.integrations_modal {
    padding: 2rem 0;
}
.integrations_imgs_modal {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: space-between;
    width: 60%;
}

.modal_contact {
    text-decoration: none;
    color: tomato;
}

.imgs_show {
    align-self: flex-end;
    display: block;
}

.imgs_show img {
    width: 26%;
    margin: 0 1rem;
}
.imgs_hide {
    display: none;
}

.next_contact {
    color: var(--mainColor);
    text-align: center;
    padding: 0 2rem;
}
@media (max-width: 1440px) {
}
@media (max-width: 1024px) {
    .imgs_show img {
        width: 23%;
        margin: 0 1rem;
    }
}
@media (max-width: 768px) {
    .inner_integrations {
        flex-direction: column;
    }
    .imgs_show img {
        width: 25%;
    }
}
@media (max-width: 480px) {
    .imgs_show img {
        width: 22%;
    }
}
