.recommendations-container {
    align-items: center;
    justify-content: center;
    padding: 2rem 20%;
}

@media (max-width: 1440px) {
    .recommendations-container {
        padding: 2rem 10%;
    }
}

@media (max-width: 1024px) {
}
@media (max-width: 768px) {
}
@media (max-width: 480px) {
    .recommendations-container {
        padding: 2rem 5%;
    }
}
