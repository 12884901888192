.hospitality_hero {
    background: url("../../Images/hospitality.jpg") center no-repeat;
    background-size: cover;
    width: 100%;
    height: 70vh;
}

.hospitality_hero .blue_overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    align-items: center;
}

.hospitality_hero h1 {
    text-align: center;
    font-size: 4rem;
    color: #ffffff;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.6);
}

.hospitality_hero p {
    text-align: center;
    color: #ffffff;
    padding: 1rem;
}

.hospitality_main {
    padding: 2rem 20%;
}

.hospitality_card {
    min-height: 30vh;
    color: var(--mainColor);
    text-align: center;
    padding: 2rem 0;
}

.sushi_card {
    min-height: 20vh;
    text-align: center;
    padding: 2rem 0;
}

.hospitality_card_img,
.sushi_card_img {
    flex: 1;
}

.hospitality_card_text {
    flex: 2;
    background-color: #f8f8f8;
}

.hospitality_card_text h2 span {
    color: tomato;
}

.hospitality_card_text p {
    padding-top: 2rem;
    font-size: 1.1rem;
    line-height: 1.3rem;
}

.sushi_card_text {
    flex: 2;
    justify-content: center;
}

.sushi_card_text p {
    font-size: 1.4rem;
    /* line-height: 1.3rem; */
    color: var(--mainColor);
}

.sushi_card_img {
    padding: 2rem;
    /* border: springgreen 1px solid; */
    /* flex-basis: 35%; */
}

.sushi_card_text p:last-of-type {
    padding-top: 2rem;
    font-weight: 300;
}

.hc1 {
    background: url("../../Images/coffee.jpg") center no-repeat;
    background-size: cover;
}
.hc2 {
    background: url("../../Images/delivery.jpg") center no-repeat;
    background-size: cover;
}

.sushi {
    background: url("../../Images/Sushi.jpg") center no-repeat;
    background-size: contain;
    background-color: #ffffff;
}

@media (max-width: 1440px) {
    .hospitality_main {
        padding: 2rem 10%;
    }
}
@media (max-width: 1024px) {
    .hospitality_hero h1 {
        font-size: 3rem;
    }
}
@media (max-width: 768px) {
    .hospitality_hero {
        height: 40vh;
    }
    .hospitality_hero h1 {
        font-size: 2.5rem;
    }
    .hospitality_hero .blue_overlay {
        justify-content: flex-end;
        padding-bottom: 2rem;
    }
    .hospitality_main {
        padding: 2rem 5%;
    }
    .sushi_card {
        flex-direction: column;
        border: 10x red solid;
    }
    .sushi_card_text p {
        font-size: 1rem;
    }
    .sushi_card_img {
        padding: 4rem;
    }
    .hospitality_card {
        flex-direction: column-reverse;
        flex-wrap: wrap;
    }
    .hospitality_card_text {
        flex-basis: 100%;
    }
    .hospitality_card_img {
        flex-basis: 50%;
        min-height: 30vh;
    }
    .sushi_card {
        padding-bottom: 0;
    }
}
@media (max-width: 480px) {
}
