.features_hero {
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  background-color: turquoise;
  background: url(../../Images/frontDeskLady.jpg) center no-repeat;
  background-size: cover;
  color: #ffffff;
}

.darkblue_overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(7, 81, 108, 0.6);
}

.features_hero .darkblue_overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  align-items: center;
}

.features_hero h1 {
  font-size: 3rem;
  width: 90%;
  /* max-width: 700px; */
  padding: 2rem;
  text-align: center;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.6);
}

.features_main {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--mainColor);
}

.features_main_top {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 20%;
  /* gap: 1rem; */
  font-size: 18px;
}

.features_main_top p {
  line-height: 1.5rem;
  text-align: center;
  /* text-align: justify; */
}

.features_list h2,
.features_main_bottom h2 {
  text-align: center;
  font-weight: 400;
  margin-bottom: 1rem;
  font-size: 2rem;
  color: var(--mainColor);
}

.feature p {
  margin-top: 1rem;
  line-height: 1.5rem;
  /* text-align: justify; */
}

.features_main_features {
  /* margin-top: 2rem; */
  display: flex;
  justify-content: center;
}

.feature {
  flex: 1;
  padding: 0 2rem;
  text-align: center;
}

.features_main_middle {
  display: flex;
  flex-direction: column;
  padding: 0 20%;
  font-size: 18px;
}

.feature_video {
  margin-bottom: 2rem;
  background-color: var(--secColor);
  width: 100%;
  padding: 4rem 20%;
}

.features_list {
  width: 100%;
  padding: 0 20%;
  padding-bottom: 2rem;
}

.features_list ul {
  list-style: square;
  min-height: 20vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 1.1rem;
}

.features_list_item {
  margin: 1rem 0 0 2rem;
  flex-basis: 20%;
}

.features_main_bottom {
  padding: 2rem 20%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #f8f8f8;
}

.features_main_bottom p {
  line-height: 1.5rem;
  font-size: 1.1rem;
}

.features_bottom {
  display: flex;
  margin-bottom: 1rem;
}

.features_bottom p {
  flex: 1;
  padding: 0 1rem;
  font-size: 1.1rem;
  line-height: 1.5rem;
  /* text-align: justify; */
  max-width: 700px;
}

.features_bottom p span {
  font-weight: 600;
}

.features_integrations {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.features_integrate_img {
  margin: 1rem 3rem;
  flex-basis: 20%;
  /* border: olive 1px solid; */
  display: flex;
  align-items: center;
}

.features_integrate_img img {
  width: 100%;
  object-fit: contain;
}

.white {
  background-color: #ffffff;
}
@media (max-width: 1440px) {
  .features_main_top {
    padding: 4rem 10%;
    gap: 1rem;
    font-size: 1.1rem;
  }
  .feature_video {
    padding: 4rem 10%;
  }

  .features_list {
    padding: 2rem 10%;
  }

  .features_main_bottom {
    padding: 1rem 10%;
  }
  .features_main_middle {
    padding: 1rem 10%;
    font-size: 1.1rem;
  }
  .features_bottom {
    margin-bottom: 2rem;
  }
}
@media (max-width: 1024px) {
  .features_hero h1 {
    font-size: 2.5rem;
  }
  .features_integrate_img {
    margin: 1rem 1rem;
    flex-basis: 25%;
  }
}
@media (max-width: 768px) {
  .features_hero {
    height: 40vh;
  }
  .features_hero h1 {
    width: 100%;
    font-size: 2rem;
  }
  .features_hero .darkblue_overlay {
    justify-content: center;
    padding-top: 3rem;
  }
  .features_main_top {
    padding: 2rem 5%;
    font-size: 1rem;
  }
  .feature_video {
    padding: 2rem 5%;
  }

  .features_list {
    padding: 1rem 0.1rem 2rem 0.3rem;
  }

  .features_main_bottom {
    padding: 1rem 5%;
  }
  .features_main_middle {
    padding: 1rem 5%;
    font-size: 1rem;
  }
  .features_main_features {
    padding-top: 2rem;
    flex-direction: column;
  }
  .features_main_features .feature:last-of-type {
    padding-top: 2rem;
  }

  .features_integrate_img img {
    flex-basis: 40%;
    height: 100%;
    width: 100%;
  }
  .features_integrate_img {
    flex-basis: 25%;
  }
  .features_main_features .feature h2 {
    font-size: 1.6rem;
    font-weight: 400;
  }
  /* .feature h2 { */
  /* } */
  .features_main_top h2,
  .features_list h2,
  .features_main_bottom h2 {
    font-size: 1.6rem;
    font-weight: 500;
  }

  .features_main_features .feature h2 {
    font-size: 1.6rem;
  }
}
@media (max-width: 480px) {
  .features_hero h1 {
    font-size: 1.6rem;
    padding: 1rem;
  }
  .features_main_top h2,
  .features_list h2,
  .features_main_bottom h2 {
    font-size: 1.6rem;
  }
  .features_list_item {
    flex-basis: 30%;
    font-size: 1rem;
  }
  .features_main_features .feature h2 {
    font-size: 1.4rem;
  }
  /* .features_list {
    padding: 1rem;
  } */
  .features_bottom {
    flex-direction: column;
  }
  .features_bottom p:first-of-type {
    padding-bottom: 2rem;
  }
}
