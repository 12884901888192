.legal_hero {
    background: url("../../Images/contract.jpg") center no-repeat;
    background-size: cover;
    width: 100%;
    height: 70vh;
}

.legal_hero .blue_overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    align-items: center;
}

.legal_hero h1 {
    text-align: center;
    font-size: 4rem;
    color: #ffffff;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.6);
}

.legal_hero p {
    color: #ffffff;
    text-align: center;
    padding: 1rem;
}

.legal_main_top {
    padding: 4rem 20%;
}

.legal_main_card {
    display: flex;
    justify-content: center;
    min-height: 30vh;
}

.legal_main_card_item {
    flex: 2;
    padding: 2rem;
    background-color: #f8f8f8;
    color: var(--mainColor);
}

.main_card_img {
    flex: 1;
}
.legal_main_card_item h2 {
    font-size: 1.4rem;
}

.legal_main_card_item p {
    padding-top: 2rem;
    font-size: 1.1rem;
    line-height: 1.5rem;
    text-align: justify;
}

.legal_main_card_item p span {
    font-weight: 500;
}

.main_card_img {
    background: url("../../Images/team.jpg") center no-repeat;
    background-size: cover;
}

.legal_main_middle {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem 20% 2rem 20%;
    color: var(--mainColor);
}

.legal_main_middle h1 {
    font-weight: 400;
    text-align: center;
    color: var(--mainColor);
}

.legal_main_middle h1 span {
    font-weight: 500;
}

.legal_main_imgs {
    width: 100%;
    display: flex;
    gap: 2rem;

    padding: 1rem 0 2rem 0;
}

.legal_main_imgs img {
    padding: 2rem;
    flex: 1;
    width: 30%;
}

.legal_integrations {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.legal_integrations h1 {
    font-weight: 400;
    color: var(--mainColor);
}

.legal_integrations h1 span {
    font-weight: 600;
}

.legal_integrations_card {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    min-height: 30vh;
}

.clio_img {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-color: #ffffff;
}

.clio_text {
    flex: 2;
    display: flex;
    align-items: center;
    background-color: #f8f8f8;
    padding: 1rem 2rem;
    font-size: 1.1rem;
    line-height: 1.5rem;
    text-align: justify;
    color: var(--mainColor);
}

.legal_more_integrations {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 1rem; */
}
/* 
.legal_integrations_card h2 {
    padding: 2rem;
    font-weight: 400;
    color: var(--secColor);
    text-align: center;
    flex: 1;
}

.legal_integrations_card h2 span {
    color: var(--mainColor);
    font-weight: 500;
}

.legal_integrations_imgs {
    flex: 2;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}

.legal_integrations_imgs img {
    width: 25%;
    margin-right: 1rem;
}

.integrations_modal {
    padding: 2rem 0;
}
.integrations_imgs_modal {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: space-between;
    width: 60%;
}

.modal_contact {
    text-decoration: none;
    color: tomato;
} */

.legal_recommendations {
    display: flex;
    align-items: center;
    min-height: 30vh;
}

.legal_recommendations_card {
    display: flex;
    min-height: 20vh;
}

.atash_img {
    flex: 1;
    background: url("../../Images/atash.jpg") center no-repeat;
    background-size: cover;
}

.atash_text {
    padding: 1rem 2rem;
    font-size: 1.5rem;
    flex: 2;
    text-align: center;
    color: #343434;
}

.atash_text p:first-of-type {
    padding-bottom: 1rem;
}

.atash_text p:last-of-type {
    font-weight: 300;
    font-size: 1.2rem;
}

.legal_bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 4rem;
}

.legal_bottom h1 {
    text-align: center;
}

.legal_why {
    display: flex;
    padding: 2rem;
    min-height: 30vh;
    color: var(--mainColor);
}

.legal_why_img {
    flex: 1;
    background: url("../../Images/smiling.jpg") center no-repeat;
    background-size: cover;
}

.legal_why_text {
    flex: 2;
    padding: 1rem;
    background-color: #f8f8f8;
}

.legal_contact p {
    padding: 2rem;
    text-align: center;
}

.flex {
    display: flex;
}

.column_center {
    flex-direction: column;
    align-items: center;
}

.row_direction {
    justify-content: center;
}

.legal_check_box {
    align-items: center;
    flex-basis: 50%;
}

.legal_check_box span {
    font-weight: 500;
}

.legal_check {
    color: tomato;
    padding-right: 1rem;
    padding-top: 0.15rem;
}

.pad12 {
    padding: 1rem 2rem;
}

.legal_why_checkes {
    flex-wrap: wrap;
    padding: 1rem;
}

@media (max-width: 1440px) {
    .legal_main_top {
        padding: 2rem 10%;
    }
    .legal_main_middle {
        padding: 2rem 10% 1rem 10%;
    }
    .legal_bottom {
        padding: 2rem;
    }
    .legal_contact {
        padding: 1rem 2rem;
    }
}
@media (max-width: 1024px) {
    .legal_hero h1 {
        font-size: 3rem;
    }
    .legal_integrations_card h2 {
        padding: 1rem;
        font-weight: 400;
        font-size: 1.2rem;
        flex-basis: 80%;
    }
    .legal_integrations_imgs {
        padding-bottom: 0;
    }
    .legal_bottom {
        padding: 0;
    }
    .legal_integrations_card {
        flex-direction: column;
    }

    .atash_text {
        font-size: 1.2rem;
    }

    .atash_text p:first-of-type {
        padding-bottom: 2rem;
    }

    .atash_text p:last-of-type {
        font-size: 1rem;
    }
    .legal_check_box {
        align-items: center;
        flex-basis: 100%;
    }
    .clio_text {
        font-size: 1rem;
        line-height: 1.2rem;
    }
}
@media (max-width: 768px) {
    .legal_hero {
        height: 40vh;
    }

    .legal_hero .blue_overlay {
        justify-content: flex-end;
        padding-bottom: 2rem;
    }

    .legal_hero h1 {
        font-size: 2.5rem;
    }
    .legal_main_top {
        padding: 2rem 5%;
    }
    .legal_main_middle {
        padding: 4rem 5% 1rem 5%;
    }
    .legal_main_middle h1 {
        font-size: 1.8rem;
    }
    .legal_bottom {
        padding: 0;
    }
    .legal_main_card {
        flex-direction: column;
    }
    .legal_recommendations_card {
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
    }

    .atash_img {
        min-height: 20vh;
        width: 40vw;
        background-size: cover;
    }
    .atash_text {
        padding: 2rem 2rem;
    }

    .legal_main_card_item h2 {
        font-size: 1.2rem;
        text-align: center;
    }
    .legal_main_card_item p {
        font-size: 1.1rem;
        line-height: 1.3rem;
    }
    .main_card_img {
        min-height: 30vh;
    }
    .legal_bottom h1 {
        font-size: 1.8rem;
    }

    .legal_why {
        padding: 1rem;
    }
    .legal_why h2 {
        padding: 1rem;
    }
    .legal_why_img {
        display: none;
    }
    .legal_check_box {
        align-items: center;
        flex-basis: 50%;
        padding: 1rem 0;
    }
    .legal_contact {
        padding: 1rem;
    }
}
@media (max-width: 480px) {
    .legal_why {
        padding-bottom: 0;
    }
    .legal_check_box {
        align-items: center;
        flex-basis: 100%;
    }
    .legal_integrations_imgs {
        padding: 0;
    }

    .legal_integrations_imgs img {
        width: 33%;
        margin-right: 0;
    }
    .atash_text {
        padding: 2rem 1rem;
        font-size: 1.2rem;
    }
}
