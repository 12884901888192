.calculator_hero {
  background: url("../../Images/payment.jpg") center no-repeat;
  background-size: cover;
  width: 100%;
  height: 40vh;
}

.calculator_hero .darkblue_overlay {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 1rem;
  align-items: center;
  padding-bottom: 3rem;
}

.calculator_hero h1 {
  font-size: 3rem;
  width: 90%;
  /* max-width: 700px; */
  color: #ffffff;
  padding: 2rem;
  text-align: center;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.6);
}

.calculator_main {
  padding: 1rem 20%;
  min-height: 60vh;
}

.calculator_last {
  padding: 1rem 20%;
  height: 49.5vh;
}

.calculator_step_content {
  align-items: center;
}

.calculator_btns {
  justify-content: center;
  width: 100%;
}

.calculator_result {
  min-height: 25vh;
  /* max-height: 30vh; */
}

@media (max-width: 1440px) {
  .calculator_main {
    padding: 1rem 10%;
  }
  .calculator_last {
    padding: 1rem 10%;
  }
  .calculator_step_content {
    padding: 0 10%;
  }
}
@media (max-width: 1024px) {
  .calculator_hero h1 {
    font-size: 2.5rem;
  }
  .calculator_main {
    padding: 1rem 5%;
  }
  .calculator_last {
    padding: 1rem 5%;
  }
  .calculator_step_content {
    padding: 0 10%;
    align-items: center;
  }
}
@media (max-width: 768px) {
  .calculator_hero .darkblue_overlay {
    justify-content: center;
    padding-top: 5rem;
  }
  .calculator_hero h1 {
    width: 100%;
    font-size: 2rem;
  }
  .calculator_step_content {
    padding: 0;
    align-items: center;
  }
}
@media (max-width: 480px) {
  .calculator_main {
    padding: 1rem;
    align-items: center;
    justify-content: center;
  }
  .calculator_hero h1 {
    font-size: 1.6rem;
    padding: 1rem;
  }
}
