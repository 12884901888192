@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Ubuntu", sans-serif;
  /* font-family: "Nunito Sans", sans-serif; */
  scroll-behavior: smooth;
}

:root {
  --newColor: rgb(14, 38, 53);
  --mainColor: rgb(7, 81, 108);
  --lightColor: rgb(133, 207, 226);
  --secColor: rgb(147, 203, 226);
  --orange: rgb(255, 99, 71);

  /* --newColor: #0e2635;
    --mainColor: #07516c;
    --lightColor: #85cfe2;
    --secColor: #93cbe2; */
}

.shadow_bottom_right {
  position: relative;
}
.shadow_bottom_right::after {
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 15px;
  right: 10px;
  left: auto;
  width: 50%;
  top: 80%;
  max-width: 300px;
  background: #555555;
  -webkit-box-shadow: 0 15px 10px #555555;
  -moz-box-shadow: 0 15px 10px #555555;
  box-shadow: 0 15px 10px #555555;
  -webkit-transform: rotate(3deg);
  -moz-transform: rotate(3deg);
  -o-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  transform: rotate(3deg);
}

.shadow-bottom-left {
  position: relative;
}

.shadow-bottom-left::before {
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 15px;
  left: 10px;
  width: 50%;
  top: 80%;
  max-width: 300px;
  background: #555555;
  -webkit-box-shadow: 0 15px 10px #555555;
  -moz-box-shadow: 0 15px 10px #555555;
  box-shadow: 0 15px 10px #555555;
  -webkit-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  -o-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg);
}
