.step h2 {
  color: tomato;
  padding-bottom: 1rem;
  text-align: center;
}

.step p {
  box-sizing: border-box;
  padding: 1rem 10rem;
  text-align: center;
}

.step p:last-of-type {
  padding-top: 1rem;
}

.step_checkes {
  flex-wrap: wrap;
  margin-left: 15%;
  /* border: peru 1px solid; */
}

.step {
  padding-bottom: 2rem;
}
.step_result {
  padding-bottom: 1rem;
}

.step_three_item {
  flex-basis: 30%;
}
.step_result {
  /* padding-bottom: 2rem; */
  align-items: center;
}

.step_result h1 span {
  color: tomato;
}

.step_result h1 {
  color: var(--secColor);
}
.step_result h3 {
  color: var(--newColor);
}

.result_bill p button {
  outline: none;
  background: none;
  color: tomato;
  font-weight: 500;
  border: none;
  font-size: 1rem;
  cursor: pointer;
}
.result_bill p:first-of-type {
  /* border: 1px red solid; */
  padding-top: 1rem;
  width: 50vw;
}

@media (max-width: 1440px) {
  /* .step_checkes {
        padding-left: 15%;
    } */
  .step p {
    padding: 1rem 4rem;
  }
}
@media (max-width: 1024px) {
  .step h2 {
    font-size: 1.5rem;
  }

  /* .result_bill p:first-of-type {
    width: 70vw;
  } */
}
@media (max-width: 768px) {
  .step h2 {
    font-size: 1.2rem;
  }
  .step_result h1 {
    text-align: center;
    font-size: 1.5rem;
  }
  /* .step p {
    padding: 1rem 5%;
  } */
  /* .step_checkes {
    margin-left: 0;
  } */

  .step_three_item {
    flex-basis: 40%;
  }
  .step_checkes {
    width: 100%;
    padding-left: 10%;
  }
  /* .step p {
    padding: 1rem 10%;
    font-size: 1rem;
  } */
  .result_bill p {
    font-size: 0.8rem;
  }
  .result_bill p:first-of-type {
    width: 80vw;
  }
}
@media (max-width: 480px) {
  .result_bill p:first-of-type {
    width: 90vw;
  }
}
