@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap);
.navbar {
  position: fixed;
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--newColor);
  z-index: 10;
}

.navbar-logo {
  margin-left: 2rem;
  color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.navbar-logo img {
  height: 2.5rem;
  margin-right: 1rem;
  border: 1px solid tomato;
  border-radius: 50%;
}

.navbar ul {
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  width: 40%;
  height: 100%;
  /* border: 1px yellow solid; */
}

.navbar ul li {
  align-self: center;
  /* border: 1px green solid; */
  height: 100%;
  display: flex;
  align-items: center;
}

.navbar a {
  text-decoration: none;
  color: #fff;
}
.navbar a:active {
  color: var(--secColor);
}

.active_nav {
  color: red;
}

.navbar h1 {
  color: #fff;
  font-weight: 300;
}

.navbar a > span:first-of-type {
  font-size: 3.5rem;
  font-weight: 400;
}
.dropbtn {
  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  /* top: 2rem; */
  left: -2rem;
  background-color: #f9f9f9;
  min-width: 8rem;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: var(--secColor);
}
.dropdown-content a:active {
  color: var(--mainColor);
}

.dropdown:hover .dropdown-content {
  display: block;
}

.hamburger {
  color: #ffffff;
  /* padding-right: 1rem; */
  display: none;
}

.list-dropdown-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 1.2rem;
  display: none;
}

.list-dropdown-content a {
  margin-top: 1rem;
}

.burger_list {
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
}

.burger_list li {
  margin-top: 1rem;
  color: yellow;
}

.sub_menu_close {
  display: none;
}
.sub_menu_open {
  display: flex;
  flex-direction: column;
  line-height: 2rem;
  background-color: #ededed;
  padding: 0.5rem 0;
}

@media (max-width: 1440px) {
  .navbar ul {
    width: 50%;
  }
}
@media (max-width: 1024px) {
  .navbar ul {
    width: 60%;
  }
  .navbar ul li {
    font-weight: 400;
  }
  .navbar h1 span {
    font-size: 2.5rem;
  }

  .hamburger {
    display: none;
  }
}
@media (max-width: 768px) {
  .navbar {
    height: 80px;
  }
  .navbar ul {
    display: none;
  }
  .navbar ul li {
    font-weight: 300;
    font-size: 0.8rem;
  }
  .navbar h1 {
    font-weight: 300;
  }

  .navbar h1 span {
    font-size: 2rem;
  }

  .hamburger {
    color: #ffffff;
    padding-right: 1rem;
    display: block;
  }

  .hamburger i {
    color: #ffffff;
  }
  .burger_list {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
    list-style: none;
    font-size: 1.2rem;
    height: 40vh;
    width: 100%;
  }
  .burger_list li a {
    text-decoration: none;
    color: var(--newColor);
  }
}

@media (max-width: 480px) {
  .navbar h1 {
    font-size: 1.1rem;
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Ubuntu", sans-serif;
  /* font-family: "Nunito Sans", sans-serif; */
  scroll-behavior: smooth;
}

:root {
  --newColor: rgb(14, 38, 53);
  --mainColor: rgb(7, 81, 108);
  --lightColor: rgb(133, 207, 226);
  --secColor: rgb(147, 203, 226);
  --orange: rgb(255, 99, 71);

  /* --newColor: #0e2635;
    --mainColor: #07516c;
    --lightColor: #85cfe2;
    --secColor: #93cbe2; */
}

.shadow_bottom_right {
  position: relative;
}
.shadow_bottom_right::after {
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 15px;
  right: 10px;
  left: auto;
  width: 50%;
  top: 80%;
  max-width: 300px;
  background: #555555;
  box-shadow: 0 15px 10px #555555;
  transform: rotate(3deg);
}

.shadow-bottom-left {
  position: relative;
}

.shadow-bottom-left::before {
  z-index: -1;
  position: absolute;
  content: "";
  bottom: 15px;
  left: 10px;
  width: 50%;
  top: 80%;
  max-width: 300px;
  background: #555555;
  box-shadow: 0 15px 10px #555555;
  transform: rotate(-3deg);
}

.step h2 {
  color: tomato;
  padding-bottom: 1rem;
  text-align: center;
}

.step p {
  box-sizing: border-box;
  padding: 1rem 10rem;
  text-align: center;
}

.step p:last-of-type {
  padding-top: 1rem;
}

.step_checkes {
  flex-wrap: wrap;
  margin-left: 15%;
  /* border: peru 1px solid; */
}

.step {
  padding-bottom: 2rem;
}
.step_result {
  padding-bottom: 1rem;
}

.step_three_item {
  flex-basis: 30%;
}
.step_result {
  /* padding-bottom: 2rem; */
  align-items: center;
}

.step_result h1 span {
  color: tomato;
}

.step_result h1 {
  color: var(--secColor);
}
.step_result h3 {
  color: var(--newColor);
}

.result_bill p button {
  outline: none;
  background: none;
  color: tomato;
  font-weight: 500;
  border: none;
  font-size: 1rem;
  cursor: pointer;
}
.result_bill p:first-of-type {
  /* border: 1px red solid; */
  padding-top: 1rem;
  width: 50vw;
}

@media (max-width: 1440px) {
  /* .step_checkes {
        padding-left: 15%;
    } */
  .step p {
    padding: 1rem 4rem;
  }
}
@media (max-width: 1024px) {
  .step h2 {
    font-size: 1.5rem;
  }

  /* .result_bill p:first-of-type {
    width: 70vw;
  } */
}
@media (max-width: 768px) {
  .step h2 {
    font-size: 1.2rem;
  }
  .step_result h1 {
    text-align: center;
    font-size: 1.5rem;
  }
  /* .step p {
    padding: 1rem 5%;
  } */
  /* .step_checkes {
    margin-left: 0;
  } */

  .step_three_item {
    flex-basis: 40%;
  }
  .step_checkes {
    width: 100%;
    padding-left: 10%;
  }
  /* .step p {
    padding: 1rem 10%;
    font-size: 1rem;
  } */
  .result_bill p {
    font-size: 0.8rem;
  }
  .result_bill p:first-of-type {
    width: 80vw;
  }
}
@media (max-width: 480px) {
  .result_bill p:first-of-type {
    width: 90vw;
  }
}

/* mobile */
/* .cont { 
    position: absolute;
    left: 50%;
    top: 50%;
    transform: Translate(-50%, -50%);
} */

/* web */
.loader-p {
  position: relative;
  margin-top: 40px;
  z-index: 155;
}
.cont {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 150;
}
.shredder {
  background-color: lightgray;
  color: var(--newColor);
  border-bottom: 6px solid var(--newColor);
  border-top: 5px solid var(--mainColor);
  width: 10rem;
  font-size: 1.2rem;
  padding: 10px 0px 10px 35px;
  border-radius: 0px 0px 10px 10px;
  box-shadow: 0px 0px 15px 1px rgba(22, 41, 102, 0.534);
  position: relative;
}

.shredder:after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: #c8d6e5;
  top: -3px;
  left: 0;
  position: absolute;
}

.loader {
  position: absolute;
  top: 20px;
  left: 34px;
}

.shredder .loader div {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: var(--newColor);
  position: absolute;
  transform-origin: -8px;
  -webkit-animation: light 3s linear infinite;
          animation: light 3s linear infinite;
}

.shredder .loader div:nth-child(1) {
  transform: rotate(0deg);
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.shredder .loader div:nth-child(2) {
  transform: rotate(60deg);
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}

.shredder .loader div:nth-child(3) {
  transform: rotate(120deg);
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

.shredder .loader div:nth-child(4) {
  transform: rotate(180deg);
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s;
}

.shredder .loader div:nth-child(5) {
  transform: rotate(240deg);
  -webkit-animation-delay: 2s;
          animation-delay: 2s;
}

.shredder .loader div:nth-child(6) {
  transform: rotate(300deg);
  -webkit-animation-delay: 2.5s;
          animation-delay: 2.5s;
}

@-webkit-keyframes light {
  0% {
    background-color: var(--newColor);
  }
  40% {
    background-color: var(--newColor);
  }
  50% {
    background-color: white;
  }
  60% {
    background-color: var(--newColor);
  }
  100% {
    background-color: var(--newColor);
  }
}

@keyframes light {
  0% {
    background-color: var(--newColor);
  }
  40% {
    background-color: var(--newColor);
  }
  50% {
    background-color: white;
  }
  60% {
    background-color: var(--newColor);
  }
  100% {
    background-color: var(--newColor);
  }
}

.paper {
  width: 8rem;
  height: 6rem;
  box-shadow: 0px 0px 10px 1px rgba(55, 55, 55, 0.3);
  left: 0;
  right: 0;
  margin: auto;
  -webkit-animation: paper 6s ease infinite;
          animation: paper 6s ease infinite;
  position: absolute;
  transform-origin: top;
  background-color: #fff;
}

/* .paper p {
  width: 100%;
  position: relative;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  transform: translateY(0px) rotate(180deg);
  animation: text 6s ease infinite;
  font-size: 1.1rem;
} */

.g-cont {
  margin: 0 auto;
  width: 8rem;
  text-align: center;
  position: absolute;
  top: 3.3rem;
  left: 0.8rem;
  right: 0;
  margin: auto;
  z-index: 10;
}

.garbage {
  height: 7rem;
  width: 6px;
  box-shadow: 0px 0px 10px 1px rgba(15, 26, 128, 0.3);
  display: inline-block;
  vertical-align: top;
  margin-right: 9px;
  -webkit-animation: garbage 6s ease infinite;
          animation: garbage 6s ease infinite;
  background-color: #fff;
}

@-webkit-keyframes garbage {
  0% {
    height: 0px;
    transform: translateY(0px);
    opacity: 0;
  }
  20% {
    height: 0px;
    transform: translateY(0px);
    opacity: 1;
  }
  50% {
    height: 80px;
    transform: translateY(0px);
    opacity: 0.5;
  }
  70% {
    height: 100px;
    transform: translateY(0px);
    opacity: 0.2;
  }
  90.1% {
    height: 120px;
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    height: 0px;
    opacity: 0;
  }
}

@keyframes garbage {
  0% {
    height: 0px;
    transform: translateY(0px);
    opacity: 0;
  }
  20% {
    height: 0px;
    transform: translateY(0px);
    opacity: 1;
  }
  50% {
    height: 80px;
    transform: translateY(0px);
    opacity: 0.5;
  }
  70% {
    height: 100px;
    transform: translateY(0px);
    opacity: 0.2;
  }
  90.1% {
    height: 120px;
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    height: 0px;
    opacity: 0;
  }
}

@-webkit-keyframes paper {
  0% {
    height: 160px;
    opacity: 0;
    transform: translateY(-100px) rotate(180deg);
  }
  20% {
    height: 160px;
    opacity: 1;
    transform: translateY(0px) rotate(180deg);
  }
  70% {
    height: 0px;
    opacity: 0.5;
    transform: translateY(0px) rotate(180deg);
  }
  70.1% {
    height: 0px;
    opacity: 0;
    transform: translateY(0px) rotate(180deg);
  }
  100% {
    height: 160px;
    opacity: 0;
    transform: translateY(0px) rotate(180deg);
  }
}

@keyframes paper {
  0% {
    height: 160px;
    opacity: 0;
    transform: translateY(-100px) rotate(180deg);
  }
  20% {
    height: 160px;
    opacity: 1;
    transform: translateY(0px) rotate(180deg);
  }
  70% {
    height: 0px;
    opacity: 0.5;
    transform: translateY(0px) rotate(180deg);
  }
  70.1% {
    height: 0px;
    opacity: 0;
    transform: translateY(0px) rotate(180deg);
  }
  100% {
    height: 160px;
    opacity: 0;
    transform: translateY(0px) rotate(180deg);
  }
}

@-webkit-keyframes text {
  0% {
    height: 160px;
    opacity: 0;
    transform: translateY(-100px) rotate(180deg);
  }
  20% {
    height: 160px;
    opacity: 1;
    transform: translateY(-0px) rotate(180deg);
  }
  70% {
    height: 0px;
    opacity: 1;
    transform: translateY(-10px) rotate(180deg);
  }
  70.1% {
    height: 0px;
    opacity: 0;
    transform: translateY(-50px) rotate(180deg);
  }
  100% {
    height: 160px;
    opacity: 0;
    transform: translateY(-180px) rotate(180deg);
  }
}

@keyframes text {
  0% {
    height: 160px;
    opacity: 0;
    transform: translateY(-100px) rotate(180deg);
  }
  20% {
    height: 160px;
    opacity: 1;
    transform: translateY(-0px) rotate(180deg);
  }
  70% {
    height: 0px;
    opacity: 1;
    transform: translateY(-10px) rotate(180deg);
  }
  70.1% {
    height: 0px;
    opacity: 0;
    transform: translateY(-50px) rotate(180deg);
  }
  100% {
    height: 160px;
    opacity: 0;
    transform: translateY(-180px) rotate(180deg);
  }
}

.calculator_hero {
  background: url(/static/media/payment.abbfaa1d.jpg) center no-repeat;
  background-size: cover;
  width: 100%;
  height: 40vh;
}

.calculator_hero .darkblue_overlay {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
  padding-bottom: 3rem;
}

.calculator_hero h1 {
  font-size: 3rem;
  width: 90%;
  /* max-width: 700px; */
  color: #ffffff;
  padding: 2rem;
  text-align: center;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.6);
}

.calculator_main {
  padding: 1rem 20%;
  min-height: 60vh;
}

.calculator_last {
  padding: 1rem 20%;
  height: 49.5vh;
}

.calculator_step_content {
  align-items: center;
}

.calculator_btns {
  justify-content: center;
  width: 100%;
}

.calculator_result {
  min-height: 25vh;
  /* max-height: 30vh; */
}

@media (max-width: 1440px) {
  .calculator_main {
    padding: 1rem 10%;
  }
  .calculator_last {
    padding: 1rem 10%;
  }
  .calculator_step_content {
    padding: 0 10%;
  }
}
@media (max-width: 1024px) {
  .calculator_hero h1 {
    font-size: 2.5rem;
  }
  .calculator_main {
    padding: 1rem 5%;
  }
  .calculator_last {
    padding: 1rem 5%;
  }
  .calculator_step_content {
    padding: 0 10%;
    align-items: center;
  }
}
@media (max-width: 768px) {
  .calculator_hero .darkblue_overlay {
    justify-content: center;
    padding-top: 5rem;
  }
  .calculator_hero h1 {
    width: 100%;
    font-size: 2rem;
  }
  .calculator_step_content {
    padding: 0;
    align-items: center;
  }
}
@media (max-width: 480px) {
  .calculator_main {
    padding: 1rem;
    align-items: center;
    justify-content: center;
  }
  .calculator_hero h1 {
    font-size: 1.6rem;
    padding: 1rem;
  }
}

.form_container {
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
    padding: 1em;
}

.form_ ul {
    list-style: none;
    padding: 0;
}

.brand {
    text-align: center;
}

.brand span {
    color: #fff;
}

.wrapper {
    box-shadow: 0 0 20px 0 rgba(72, 94, 116, 0.7);
}

.wrapper > * {
    padding: 1em;
}

.company-info {
    background: #c9e6ff;
}

.company-info h3,
.company-info ul {
    text-align: center;
    margin: 0 0 1rem 0;
}

.form_contact {
    background: #f9feff;
}

/* FORM STYLES */
.form_contact form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

.form_contact form label {
    display: block;
}

.form_contact form p {
    margin: 0;
}

.form_contact form .full {
    grid-column: 1 / 3;
}

.form_contact form button,
.form_contact form input,
.form_contact form textarea {
    width: 100%;
    padding: 1em;
    border: 1px solid #c9e6ff;
}

.form_contact form button {
    background: #c9e6ff;
    border: 0;
    text-transform: uppercase;
}

.form_contact form button:hover,
.form_contact form button:focus {
    background: #92bde7;
    color: #fff;
    outline: 0;
    transition: background-color 2s ease-out;
}

/* LARGE SCREENS */
@media (min-width: 700px) {
    .wrapper {
        display: grid;
        grid-template-columns: 1fr 2fr;
    }

    .wrapper > * {
        padding: 2em;
    }

    .company-info h3,
    .company-info ul,
    .brand {
        text-align: left;
    }
}

.landing {
  /* height: calc(100vh - 100px); */
  height: 100vh;
  width: 100%;
  background: url(/static/media/Home_banner.5a54c3f3.jpg) no-repeat;
  background-size: cover;
  background-position: top;
  display: flex;
  align-items: center;
  background-color: var(--newColor);
}

.landing .overlay {
  width: 100%;
  height: 100%;
  padding: 10%;
  background-image: linear-gradient(to right, var(--newColor), transparent);
  display: flex;
  align-items: center;
}

.landing h1 {
  color: #fff;
  line-height: 3rem;
}

.landing .welcome {
  /* position: fixed; */
  width: 30rem;
  color: #fff;
  font-size: 1.2rem;
  line-height: 2rem;
  /* border: 1px red solid; */
}

.landing .welcome-text {
  font-weight: 300;
  margin-bottom: 2rem;
  margin-top: 1rem;
}
@media (max-width: 1440px) {
}
@media (max-width: 1024px) {
  .landing {
    background-position: 80%;
  }
  .landing .welcome {
    align-self: flex-end;
    padding-top: 4rem;
  }
}
@media (max-width: 768px) {
  .landing {
    background-position: 70%;
    /* opacity: 0.8; */
  }

  .landing h1 {
    font-size: 2rem;
    font-weight: 400;
  }

  .landing .welcome {
    max-width: 80%;
    align-self: flex-end;
    padding-bottom: 4rem;
  }

  .landing .welcome-text {
    font-size: 1rem;
  }
}
@media (max-width: 480px) {
  /* .landing {
        background-position: 60%;
    } */
  .landing h1 {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
  .landing .welcome {
    max-width: 100%;
    align-self: flex-end;
    padding-bottom: 5rem;
  }
}

.info-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 4rem;
  color: var(--newColor);
  font-size: 18px;
  line-height: 1.5rem;
}

.info-container h2 {
  font-size: 2rem;
  font-weight: 400;
  line-height: 2.4rem;
  text-align: center;
  color: var(--newColor);
}

.info-video {
  width: 80vw;
  height: 63vh;
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  background-color: #ffffff;
}

.info-container .infos {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  grid-gap: 2rem;
  gap: 2rem;
  padding: 2rem 20%;
}

.infos .info-card {
  flex: 1 1;
  padding: 1rem;
  display: grid;
  grid-template-rows: 1fr 2fr 0.3fr;
  border-radius: 10px;
}

.info-card h2 {
  font-size: 2rem;
  text-align: center;
  line-height: 2.2rem;
  margin-bottom: 1rem;
}

/* .info-card:first-of-type h2 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 30%;
} */

.info-card p {
  margin-bottom: 2rem;
  /* text-align: justify; */
}

.info-card p span {
  font-weight: 600;
}

.card-img {
  border-radius: 50%;
  width: 25%;
  /* height: 300px; */
}

.info-solutions {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 20%;
  background-color: #f8f8f8;
  color: #f8f8f8;
}

.info-solutions h2 {
  font-size: 2rem;
  font-weight: 400;
}

.info-solutions p {
  margin-top: 2rem;
  color: var(--newColor);
}

.industries-btns {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  width: 100%;
  padding: 2rem 1rem 0 1rem;
}

.industry-btn {
  flex: 1 1;
  flex-basis: 20%;
  height: 3rem;
  /* border: 2px solid var(--mainColor); */
  border-radius: 0.3rem;
  padding: 0.8rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 400;
  background-color: var(--mainColor);
  margin: 0.5rem;
  cursor: pointer;
}

.industry-btn:hover {
  background-color: var(--newColor);
}

.industry-btn a {
  color: #f8f8f8;
  text-decoration: none;
  text-transform: uppercase;
  margin-left: 1rem;
}

.info-customize {
  padding: 1rem 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.divider {
  width: 50%;
  border-bottom: 1px solid #eeeeee;
  margin: 2rem;
}

.info-customize h2,
.info-how h2,
.info-compare h2 {
  font-size: 2rem;
  font-weight: 400;
  line-height: 2.4rem;
  text-align: center;
}

.info-customize ul {
  margin-top: 3rem;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding-left: 15%;
}

.info-customize ul li {
  flex: 1 1;
  flex-basis: 40%;
  font-size: 1.5rem;
  padding: 1rem;
}

.info-customize ul li {
  display: flex;
  align-items: center;
}

.info-how {
  padding: 0 20% 4rem 20%;
}

.info-how-cards {
  display: flex;
  /* gap: 1rem; */
  justify-content: space-between;
  padding-top: 2rem;
}

.how-card {
  /* flex: 1; */
  flex-basis: 31%;
  padding: 2rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
  background-color: #f8f8f8;
  /* box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.4); */
}

.how-card h3 {
  text-align: center;
  margin-bottom: 1rem;
}

.how-card p {
  margin-bottom: 1rem;
  /* text-align: justify; */
}

.how-card p span {
  font-weight: 500;
}

.info_check {
  color: var(--mainColor);
  padding-right: 1rem;
  padding-top: 0.15rem;
}
.orange_check {
  color: tomato;
  padding-right: 1rem;
  padding-top: 0.15rem;
}

.info-compare {
  /* padding: 0 10% 3rem 10%; */
  width: 100%;
}

.info-compare h1 {
  padding-bottom: 2rem;
}

.info-compare p {
  font-weight: 400;
  margin-bottom: 2rem;
  text-align: center;
}

.info-table-container {
  width: 100%;
  /* border: red 1px solid; */
}

.info-table {
  color: var(--mainColor);
}

.info_btn {
  background-color: var(--mainColor);
  /* color: #ffffff; */
  color: red;
}

.customize-item:last-of-type {
  color: tomato;
}
@media (max-width: 1440px) {
  .info-card h2 {
    line-height: 2rem;
    margin-bottom: 1rem;
  }
  .info-container .infos {
    padding: 2rem 10%;
  }
  .info-card:first-of-type h2 {
    padding: 0 35%;
    font-weight: 500;
  }
  .info-card:last-of-type h2 {
    font-weight: 500;
    padding: 0 9%;
  }
  .info-solutions {
    padding: 2rem 10%;
  }

  .info-customize {
    padding: 2rem 10%;
  }

  .info-customize ul {
    margin-top: 2rem;
    padding-left: 20%;
  }

  .info-customize ul li {
    flex: 1 1;
    flex-basis: 40%;
    font-size: 1.3rem;
    padding: 1rem;
  }

  .info-how {
    padding: 0 10% 2rem 10%;
  }

  .how-card {
    /* flex: 1; */
    flex-basis: 31%;
    padding: 2rem;
    border-radius: 0.5rem;
    background-color: #f8f8f8;
    /* box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.4); */
  }

  .how-card p {
    text-align: left;
    font-size: 1rem;
    line-height: 1.3rem;
  }
  .how-card p:nth-last-of-type(1) {
    margin-bottom: 0;
  }

  .info-compare {
    padding: 0 10% 3rem 10%;
  }
}
@media (max-width: 1024px) {
  .info-container .infos {
    padding: 2rem 5%;
  }

  .infos .info-card {
    flex: 1 1;
  }

  .info-container h1 {
    font-size: 2.5rem;
  }
  .info-video {
    width: 100%;
    max-height: 100%;
    margin-top: 4rem;
    padding: 0 10%;
  }
  .info-card h2 {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 1rem;
  }
  .info-card p {
    font-size: 1rem;
  }
  .info-card:first-of-type h2 {
    padding: 0 32%;
    font-weight: 500;
  }
  .info-card:last-of-type h2 {
    font-weight: 500;
    padding: 0;
  }
  .info-container .infos {
    flex-direction: row;
  }

  .info-customize h1,
  .info-how h1,
  .info-compare h1,
  .info-solutions h1 {
    font-size: 2.5rem;
  }

  .industries-btns {
    flex-wrap: wrap;
  }

  .industry-btn {
    flex-basis: 40%;
  }
  .info-customize {
    padding: 2rem 10%;
  }

  .info-customize ul {
    margin-top: 1rem;
    padding-left: 15%;
  }

  .info-customize h1 {
    font-size: 1.9rem;
    line-height: 2.5rem;
  }

  .info-how {
    padding: 0 5% 2rem 5%;
  }

  .how-card {
    flex-basis: 32%;
    padding: 1rem;
  }
}
@media (max-width: 768px) {
  .info-card:first-of-type h2 {
    padding: 0;
    font-weight: 500;
  }
  .info-container .infos {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    grid-gap: 2rem;
    gap: 2rem;
  }

  .info-solutions p {
    text-align: center;
    font-size: 1rem;
  }

  .info-customize h1,
  .info-how h1,
  .info-compare h1,
  .info-solutions h1 {
    font-size: 1.8rem;
    line-height: 2.5rem;
  }

  .info-customize {
    padding: 1rem 5%;
  }

  .info-customize ul {
    margin-top: 2rem;
    padding-left: 5%;
  }

  .info-customize ul li {
    /* flex: 1; */
    flex-basis: 30%;
    font-size: 1.1rem;
    padding: 1rem;
    /* border: 1px blue solid; */
  }

  .info-how-cards {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
    justify-content: space-between;
    padding-top: 2rem;
  }

  .how-card h3 {
    font-weight: 500;
  }
  /* 
    .how-card p {
        font-size: 1rem;
    } */
  .info-compare {
    padding: 0 5% 1rem 5%;
  }
}
@media (max-width: 480px) {
  .info-video {
    height: 30vh;
    margin-top: 2rem;
  }
  .industry-btn {
    flex-basis: 80%;
    margin: 0.5rem;
  }

  .how-card {
    flex-basis: 80%;
    margin: 0.5rem;
  }
}

.recommendations-container {
    align-items: center;
    justify-content: center;
    padding: 2rem 20%;
}

@media (max-width: 1440px) {
    .recommendations-container {
        padding: 2rem 10%;
    }
}

@media (max-width: 1024px) {
}
@media (max-width: 768px) {
}
@media (max-width: 480px) {
    .recommendations-container {
        padding: 2rem 5%;
    }
}

.about_hero {
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
  background: url(/static/media/hotelRooms.16450d93.jpg) center no-repeat;
  background-size: cover;
  background-position: center;
  color: #ffffff;
}

/* .blue_overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(146, 203, 226, 0.6);
} */

.darkblue_overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(7, 81, 108, 0.6);
}

.about_hero .darkblue_overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
}

.about_hero h1 {
  font-size: 3rem;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.6);
  text-align: center;
  padding: 2rem;
  width: 90%;
  max-width: 700px;
}

.about_main {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 4rem 25%; */
  color: var(--mainColor);
}

.about_main_top {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  grid-gap: 1rem;
  gap: 1rem;
  font-size: 1.2rem;
}

.about_main_top h1 {
  font-size: 3rem;
  font-weight: 400;
}

.about_main_top p {
  margin-top: 1.4rem;
  line-height: 1.8rem;
  /* text-align: justify; */
  width: 100%;
  max-width: 700px;
}

.about_main_middle,
.about_main_bottom {
  display: flex;
  width: 100%;
  height: 40vh;
  overflow: hidden;
  /* border: 1px orangered solid; */
  margin-top: 1rem;
  padding: 0 15%;
}

.about_middle_text,
.about_bottom_text {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
  text-align: center;
}

.about_middle_text h2 {
  font-size: 2rem;
  font-weight: 400;
  width: 90%;
}
.about_middle_text p {
  padding-top: 1rem;
  font-style: italic;
}

.about_middle_img {
  flex: 1 1;
  background: url(/static/media/teamwork.28222651.jpg) center no-repeat;
  background-size: cover;
}

.about_bottom_img {
  flex: 1 1;
  background: url(/static/media/setsapart.a02ec21a.jpg) center no-repeat;
  background-size: cover;
  color: #fff;
}

.about_bottom_img .darkblue_overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-size: 1rem; */
}

.about_bottom_img .darkblue_overlay h1 {
  font-weight: 400;
  line-height: 4rem;
  font-size: 3rem;
}

.about_bottom_text p {
  font-size: 1.2rem;
  line-height: 1.7rem;
  padding: 2rem;
}

.about_main_bottom {
  padding-bottom: 2rem;
  flex-direction: row-reverse;
}

.learn-more {
  display: flex;
  /* padding: 2rem 20%; */
  width: 100%;
  max-width: 1200px;
  padding-bottom: 2rem;
}

.learn-more h3 {
  padding-bottom: 1rem;
}

.learn-more-form {
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.learn-more-form input {
  line-height: 2rem;
  width: 80%;
  min-width: 22ch;
  border-radius: 5px;
  border: 1px solid grey;
  padding-left: 1ch;
}

.learn-more-text {
  font-size: 1.2rem;
  line-height: 1.8rem;
  flex: 1 1;
  /* padding-left: 1rem; */
  /* text-align: justify; */
  max-width: 700px;
}

.about_main h1 {
  font-size: 3rem;
  font-weight: 400;
  color: var(--newColor);
  text-align: center;
  padding: 0 1rem;
}

@media (max-width: 1440px) {
  .about_main_top {
    padding: 2rem 10%;
  }
  .about_bottom_img .darkblue_overlay h1 {
    line-height: 3rem;
    font-size: 2.5rem;
  }
  .learn-more {
    padding: 2rem 10%;
  }
  .about_main h1 {
    font-size: 2.5rem;
  }
}
@media (max-width: 1024px) {
  .about_hero h1 {
    font-size: 3rem;
  }
  .about_main_top h1 {
    font-size: 2.5rem;
  }

  .about_main_top p {
    margin-top: 1rem;
    font-size: 1rem;
    line-height: 1.5rem;
    /* text-align: justify; */
  }

  .about_middle_text,
  .about_bottom_text {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
    padding: 0 10%;
  }

  .about_middle_text h2 {
    font-size: 1.7rem;
    font-weight: 400;
  }

  .about_bottom_img .darkblue_overlay h1 {
    line-height: 3rem;
    font-size: 2rem;
  }
  .about_bottom_img .darkblue_overlay {
    padding: 2rem;
    text-align: center;
  }
  .about_bottom_text p {
    font-size: 1.2rem;
    line-height: 1.5rem;
    padding: 1rem;
  }
  .about_main h1 {
    font-size: 2.5rem;
  }
}
@media (max-width: 768px) {
  .about_hero {
    height: 40vh;
  }
  .about_hero h1 {
    width: 100%;
    font-size: 2rem;
  }

  .about_hero .darkblue_overlay {
    justify-content: center;
    padding-top: 3rem;
  }
  /* .about_bottom_img .darkblue_overlay {
    flex-wrap: wrap;
    padding: 1rem;
    text-align: center;
  } */

  .about_middle_text h2 {
    font-size: 1.4rem;
    padding-top: 1rem;
    font-weight: 400;
  }
  .about_bottom_text p {
    font-size: 1rem;
    line-height: 1.2rem;
    padding: 0.5rem;
  }

  .about_main_middle,
  .about_main_bottom {
    flex-direction: column-reverse;
    padding: 0 10%;
  }

  .about_main_bottom {
    flex-direction: column-reverse;
    padding-top: 1rem;
    padding: 0 10%;
  }
  .about_main h1 {
    font-size: 2rem;
  }
  .learn-more-text {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
@media (max-width: 480px) {
  .about_hero h1 {
    font-size: 1.6rem;
    padding: 1rem;
  }
  .about_main_top p {
    font-size: 1rem;
    line-height: 1.2rem;
  }

  .about_bottom_text p {
    font-size: 1rem;
    line-height: 1.2rem;
    padding: 1rem;
  }

  .about_middle_text h2 {
    font-size: 1.2rem;
    font-weight: 400;
  }

  .about_main_middle,
  .about_main_bottom {
    padding: 0 5%;
  }
  .learn-more {
    padding: 2rem 5%;
  }
  .about_main h1 {
    font-size: 1.5rem;
  }
}

.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact_hero {
  height: 70vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background: url(/static/media/hanged_phone.139d84ac.jpg) center no-repeat;
  background-size: cover;
  background-position: 50% 0%;
}

.contact_hero .darkblue_overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
}

.contact_hero h1 {
  font-size: 3rem;
  text-align: center;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.6);
  padding: 2rem;
  width: 60%;
}

.contact_details {
  display: flex;
  flex-direction: column;
  padding: 4rem 20%;
  text-align: center;
  color: var(--mainColor);
}

.contact_details p {
  font-size: 1.2rem;
  line-height: 1.5rem;
  padding: 0 10%;
}

.contact_contacts {
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 2rem;
  font-size: 1.3rem;
}

.contact_contacts h4 span {
  font-size: 1.1rem;
  font-weight: 400;
}

.contact_form {
  display: flex;
  justify-content: center;
  padding-bottom: 3rem;
  /* border: 1px solid rebeccapurple; */
}

/* .contact_form_item {
    width: 40%;
} */

.contact_form_btn {
  padding: 1rem;
  width: 100%;
}
/* 
.Contact_message {
    width: 100%;
} */

.contact_textarea {
  padding: 1rem;
  /* width: 750px; */
  /* border: 1px red solid; */
}

.form_field {
  flex-basis: 48%;
}

.form_error {
  font-size: 14px;
  color: red;
  padding-left: 1rem;
  width: 100%;
  text-align: left;
  padding: 0.2rem 3rem;
}
.form_error_learn {
  font-size: 14px;
  color: red;
  padding-left: 1rem;
  width: 100%;
  text-align: left;
  padding: 0.2rem;
}

.pad {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  /* border: 1px red solid; */
  width: 750px;
}
/* @media (min-width: 1441px) {
} */
@media (max-width: 1440px) {
  .contact_form {
    padding: 0 10% 2rem 10%;
  }
}
@media (max-width: 1024px) {
  .contact_hero h1 {
    font-size: 2.5rem;
  }
  .contact_details {
    padding: 3rem 10%;
  }
  .contact_form {
    padding: 0 5% 1rem 5%;
  }

  .pad {
    width: 650px;
  }
}
@media (max-width: 768px) {
  .contact_hero {
    height: 40vh;
  }
  .contact_hero h1 {
    width: 100%;
    font-size: 2rem;
  }

  .contact_hero .darkblue_overlay {
    justify-content: center;
    padding-top: 3rem;
  }
  .contact_details {
    padding: 3rem 5%;
  }

  .contact_details p {
    font-size: 1rem;
    line-height: 1.2rem;
    padding: 0 5%;
  }

  .contact_contacts {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 1rem;
    gap: 1rem;
    font-size: 1.1rem;
    font-weight: 400;
  }

  .pad {
    width: 550px;
  }
}

@media (max-width: 480px) {
  .form_field {
    flex-basis: 100%;
    padding-bottom: 1rem;
  }
  .pad {
    width: 380px;
    flex-direction: column;
    align-content: space-between;
    height: 180px;
  }
}

footer {
  position: relative;
  bottom: 0;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: var(--newColor);
  color: #ffffff;
  padding: 1rem 3rem;
}

footer ul {
  list-style: none;
  margin-bottom: 1rem;
}

footer ul li {
  margin-top: 1rem;
  font-weight: 500;
}

footer a {
  text-decoration: none;
  color: #ffffff;
}

.footer_rights {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-start;
}

.footer-divider {
  font-size: 1.2rem;
  font-weight: 300;
  margin: 0 1rem;
  transform: translateY(-2px);
}

@media (max-width: 1440px) {
}
@media (max-width: 1024px) {
  .footer_rights span {
    font-size: 0.8rem;
    line-height: 1.2rem;
  }
}
@media (max-width: 768px) {
  .footer_rights {
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
  }
  .footer-divider {
    display: none;
  }
}
@media (max-width: 480px) {
  .footer_rights span {
    font-size: 0.6rem;
    line-height: 1.2rem;
  }
}

.info-compare-container {
  box-sizing: border-box;
  /* padding-top: 2rem; */
  width: 90%;
  max-width: 1200px;
  padding-bottom: 2rem;
}

.info-compare {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.info-compare h2 {
  font-size: 2rem;
  color: var(--mainColor);
  margin-bottom: 1rem;
}

@media (max-width: 1440px) {
}
@media (max-width: 1024px) {
  .info-compare {
    width: 80vw;
  }
}
@media (max-width: 768px) {
  .info-compare {
    width: 90vw;
  }
  .info-compare h2 {
    font-size: 1.6rem;
    font-weight: 500;
  }
}
@media (max-width: 480px) {
}

.features_hero {
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
  background-color: turquoise;
  background: url(/static/media/frontDeskLady.eff13080.jpg) center no-repeat;
  background-size: cover;
  color: #ffffff;
}

.darkblue_overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(7, 81, 108, 0.6);
}

.features_hero .darkblue_overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
}

.features_hero h1 {
  font-size: 3rem;
  width: 90%;
  /* max-width: 700px; */
  padding: 2rem;
  text-align: center;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.6);
}

.features_main {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--mainColor);
}

.features_main_top {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 20%;
  /* gap: 1rem; */
  font-size: 18px;
}

.features_main_top p {
  line-height: 1.5rem;
  text-align: center;
  /* text-align: justify; */
}

.features_list h2,
.features_main_bottom h2 {
  text-align: center;
  font-weight: 400;
  margin-bottom: 1rem;
  font-size: 2rem;
  color: var(--mainColor);
}

.feature p {
  margin-top: 1rem;
  line-height: 1.5rem;
  /* text-align: justify; */
}

.features_main_features {
  /* margin-top: 2rem; */
  display: flex;
  justify-content: center;
}

.feature {
  flex: 1 1;
  padding: 0 2rem;
  text-align: center;
}

.features_main_middle {
  display: flex;
  flex-direction: column;
  padding: 0 20%;
  font-size: 18px;
}

.feature_video {
  margin-bottom: 2rem;
  background-color: var(--secColor);
  width: 100%;
  padding: 4rem 20%;
}

.features_list {
  width: 100%;
  padding: 0 20%;
  padding-bottom: 2rem;
}

.features_list ul {
  list-style: square;
  min-height: 20vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 1.1rem;
}

.features_list_item {
  margin: 1rem 0 0 2rem;
  flex-basis: 20%;
}

.features_main_bottom {
  padding: 2rem 20%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #f8f8f8;
}

.features_main_bottom p {
  line-height: 1.5rem;
  font-size: 1.1rem;
}

.features_bottom {
  display: flex;
  margin-bottom: 1rem;
}

.features_bottom p {
  flex: 1 1;
  padding: 0 1rem;
  font-size: 1.1rem;
  line-height: 1.5rem;
  /* text-align: justify; */
  max-width: 700px;
}

.features_bottom p span {
  font-weight: 600;
}

.features_integrations {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.features_integrate_img {
  margin: 1rem 3rem;
  flex-basis: 20%;
  /* border: olive 1px solid; */
  display: flex;
  align-items: center;
}

.features_integrate_img img {
  width: 100%;
  object-fit: contain;
}

.white {
  background-color: #ffffff;
}
@media (max-width: 1440px) {
  .features_main_top {
    padding: 4rem 10%;
    grid-gap: 1rem;
    gap: 1rem;
    font-size: 1.1rem;
  }
  .feature_video {
    padding: 4rem 10%;
  }

  .features_list {
    padding: 2rem 10%;
  }

  .features_main_bottom {
    padding: 1rem 10%;
  }
  .features_main_middle {
    padding: 1rem 10%;
    font-size: 1.1rem;
  }
  .features_bottom {
    margin-bottom: 2rem;
  }
}
@media (max-width: 1024px) {
  .features_hero h1 {
    font-size: 2.5rem;
  }
  .features_integrate_img {
    margin: 1rem 1rem;
    flex-basis: 25%;
  }
}
@media (max-width: 768px) {
  .features_hero {
    height: 40vh;
  }
  .features_hero h1 {
    width: 100%;
    font-size: 2rem;
  }
  .features_hero .darkblue_overlay {
    justify-content: center;
    padding-top: 3rem;
  }
  .features_main_top {
    padding: 2rem 5%;
    font-size: 1rem;
  }
  .feature_video {
    padding: 2rem 5%;
  }

  .features_list {
    padding: 1rem 0.1rem 2rem 0.3rem;
  }

  .features_main_bottom {
    padding: 1rem 5%;
  }
  .features_main_middle {
    padding: 1rem 5%;
    font-size: 1rem;
  }
  .features_main_features {
    padding-top: 2rem;
    flex-direction: column;
  }
  .features_main_features .feature:last-of-type {
    padding-top: 2rem;
  }

  .features_integrate_img img {
    flex-basis: 40%;
    height: 100%;
    width: 100%;
  }
  .features_integrate_img {
    flex-basis: 25%;
  }
  .features_main_features .feature h2 {
    font-size: 1.6rem;
    font-weight: 400;
  }
  /* .feature h2 { */
  /* } */
  .features_main_top h2,
  .features_list h2,
  .features_main_bottom h2 {
    font-size: 1.6rem;
    font-weight: 500;
  }

  .features_main_features .feature h2 {
    font-size: 1.6rem;
  }
}
@media (max-width: 480px) {
  .features_hero h1 {
    font-size: 1.6rem;
    padding: 1rem;
  }
  .features_main_top h2,
  .features_list h2,
  .features_main_bottom h2 {
    font-size: 1.6rem;
  }
  .features_list_item {
    flex-basis: 30%;
    font-size: 1rem;
  }
  .features_main_features .feature h2 {
    font-size: 1.4rem;
  }
  /* .features_list {
    padding: 1rem;
  } */
  .features_bottom {
    flex-direction: column;
  }
  .features_bottom p:first-of-type {
    padding-bottom: 2rem;
  }
}

.integrations {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.integrations h1 {
    font-weight: 400;
    color: var(--mainColor);
}

.integrations h1 span {
    font-weight: 600;
}

.integrations_card {
    /* height: fit-content; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: center;
    padding: 1rem 1rem;
    /* border: green 1px solid; */
    min-height: 30vh;
}

.integrations_card p {
    color: var(--mainColor);
}

.inner_integrations {
    display: flex;
    justify-content: center;
    align-items: center;
}

.integrations_card h2 {
    padding: 2rem;
    font-weight: 400;
    color: var(--secColor);
    text-align: center;
    flex: 1 1;
}

.integrations_card h2 span {
    color: var(--mainColor);
    font-weight: 500;
}

.integrations_imgs {
    flex: 2 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}

.integrations_imgs img {
    width: 25%;
    margin-right: 1rem;
}

.integrations_modal {
    padding: 2rem 0;
}
.integrations_imgs_modal {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 2rem;
    gap: 2rem;
    justify-content: space-between;
    width: 60%;
}

.modal_contact {
    text-decoration: none;
    color: tomato;
}

.imgs_show {
    align-self: flex-end;
    display: block;
}

.imgs_show img {
    width: 26%;
    margin: 0 1rem;
}
.imgs_hide {
    display: none;
}

.next_contact {
    color: var(--mainColor);
    text-align: center;
    padding: 0 2rem;
}
@media (max-width: 1440px) {
}
@media (max-width: 1024px) {
    .imgs_show img {
        width: 23%;
        margin: 0 1rem;
    }
}
@media (max-width: 768px) {
    .inner_integrations {
        flex-direction: column;
    }
    .imgs_show img {
        width: 25%;
    }
}
@media (max-width: 480px) {
    .imgs_show img {
        width: 22%;
    }
}

.legal_hero {
    background: url(/static/media/contract.5d4d6266.jpg) center no-repeat;
    background-size: cover;
    width: 100%;
    height: 70vh;
}

.legal_hero .blue_overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
}

.legal_hero h1 {
    text-align: center;
    font-size: 4rem;
    color: #ffffff;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.6);
}

.legal_hero p {
    color: #ffffff;
    text-align: center;
    padding: 1rem;
}

.legal_main_top {
    padding: 4rem 20%;
}

.legal_main_card {
    display: flex;
    justify-content: center;
    min-height: 30vh;
}

.legal_main_card_item {
    flex: 2 1;
    padding: 2rem;
    background-color: #f8f8f8;
    color: var(--mainColor);
}

.main_card_img {
    flex: 1 1;
}
.legal_main_card_item h2 {
    font-size: 1.4rem;
}

.legal_main_card_item p {
    padding-top: 2rem;
    font-size: 1.1rem;
    line-height: 1.5rem;
    text-align: justify;
}

.legal_main_card_item p span {
    font-weight: 500;
}

.main_card_img {
    background: url(/static/media/team.07b07511.jpg) center no-repeat;
    background-size: cover;
}

.legal_main_middle {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem 20% 2rem 20%;
    color: var(--mainColor);
}

.legal_main_middle h1 {
    font-weight: 400;
    text-align: center;
    color: var(--mainColor);
}

.legal_main_middle h1 span {
    font-weight: 500;
}

.legal_main_imgs {
    width: 100%;
    display: flex;
    grid-gap: 2rem;
    gap: 2rem;

    padding: 1rem 0 2rem 0;
}

.legal_main_imgs img {
    padding: 2rem;
    flex: 1 1;
    width: 30%;
}

.legal_integrations {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.legal_integrations h1 {
    font-weight: 400;
    color: var(--mainColor);
}

.legal_integrations h1 span {
    font-weight: 600;
}

.legal_integrations_card {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    min-height: 30vh;
}

.clio_img {
    flex: 1 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-color: #ffffff;
}

.clio_text {
    flex: 2 1;
    display: flex;
    align-items: center;
    background-color: #f8f8f8;
    padding: 1rem 2rem;
    font-size: 1.1rem;
    line-height: 1.5rem;
    text-align: justify;
    color: var(--mainColor);
}

.legal_more_integrations {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 1rem; */
}
/* 
.legal_integrations_card h2 {
    padding: 2rem;
    font-weight: 400;
    color: var(--secColor);
    text-align: center;
    flex: 1;
}

.legal_integrations_card h2 span {
    color: var(--mainColor);
    font-weight: 500;
}

.legal_integrations_imgs {
    flex: 2;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}

.legal_integrations_imgs img {
    width: 25%;
    margin-right: 1rem;
}

.integrations_modal {
    padding: 2rem 0;
}
.integrations_imgs_modal {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: space-between;
    width: 60%;
}

.modal_contact {
    text-decoration: none;
    color: tomato;
} */

.legal_recommendations {
    display: flex;
    align-items: center;
    min-height: 30vh;
}

.legal_recommendations_card {
    display: flex;
    min-height: 20vh;
}

.atash_img {
    flex: 1 1;
    background: url(/static/media/atash.ab3850ec.jpg) center no-repeat;
    background-size: cover;
}

.atash_text {
    padding: 1rem 2rem;
    font-size: 1.5rem;
    flex: 2 1;
    text-align: center;
    color: #343434;
}

.atash_text p:first-of-type {
    padding-bottom: 1rem;
}

.atash_text p:last-of-type {
    font-weight: 300;
    font-size: 1.2rem;
}

.legal_bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem 4rem;
}

.legal_bottom h1 {
    text-align: center;
}

.legal_why {
    display: flex;
    padding: 2rem;
    min-height: 30vh;
    color: var(--mainColor);
}

.legal_why_img {
    flex: 1 1;
    background: url(/static/media/smiling.1f4fe7d6.jpg) center no-repeat;
    background-size: cover;
}

.legal_why_text {
    flex: 2 1;
    padding: 1rem;
    background-color: #f8f8f8;
}

.legal_contact p {
    padding: 2rem;
    text-align: center;
}

.flex {
    display: flex;
}

.column_center {
    flex-direction: column;
    align-items: center;
}

.row_direction {
    justify-content: center;
}

.legal_check_box {
    align-items: center;
    flex-basis: 50%;
}

.legal_check_box span {
    font-weight: 500;
}

.legal_check {
    color: tomato;
    padding-right: 1rem;
    padding-top: 0.15rem;
}

.pad12 {
    padding: 1rem 2rem;
}

.legal_why_checkes {
    flex-wrap: wrap;
    padding: 1rem;
}

@media (max-width: 1440px) {
    .legal_main_top {
        padding: 2rem 10%;
    }
    .legal_main_middle {
        padding: 2rem 10% 1rem 10%;
    }
    .legal_bottom {
        padding: 2rem;
    }
    .legal_contact {
        padding: 1rem 2rem;
    }
}
@media (max-width: 1024px) {
    .legal_hero h1 {
        font-size: 3rem;
    }
    .legal_integrations_card h2 {
        padding: 1rem;
        font-weight: 400;
        font-size: 1.2rem;
        flex-basis: 80%;
    }
    .legal_integrations_imgs {
        padding-bottom: 0;
    }
    .legal_bottom {
        padding: 0;
    }
    .legal_integrations_card {
        flex-direction: column;
    }

    .atash_text {
        font-size: 1.2rem;
    }

    .atash_text p:first-of-type {
        padding-bottom: 2rem;
    }

    .atash_text p:last-of-type {
        font-size: 1rem;
    }
    .legal_check_box {
        align-items: center;
        flex-basis: 100%;
    }
    .clio_text {
        font-size: 1rem;
        line-height: 1.2rem;
    }
}
@media (max-width: 768px) {
    .legal_hero {
        height: 40vh;
    }

    .legal_hero .blue_overlay {
        justify-content: flex-end;
        padding-bottom: 2rem;
    }

    .legal_hero h1 {
        font-size: 2.5rem;
    }
    .legal_main_top {
        padding: 2rem 5%;
    }
    .legal_main_middle {
        padding: 4rem 5% 1rem 5%;
    }
    .legal_main_middle h1 {
        font-size: 1.8rem;
    }
    .legal_bottom {
        padding: 0;
    }
    .legal_main_card {
        flex-direction: column;
    }
    .legal_recommendations_card {
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
    }

    .atash_img {
        min-height: 20vh;
        width: 40vw;
        background-size: cover;
    }
    .atash_text {
        padding: 2rem 2rem;
    }

    .legal_main_card_item h2 {
        font-size: 1.2rem;
        text-align: center;
    }
    .legal_main_card_item p {
        font-size: 1.1rem;
        line-height: 1.3rem;
    }
    .main_card_img {
        min-height: 30vh;
    }
    .legal_bottom h1 {
        font-size: 1.8rem;
    }

    .legal_why {
        padding: 1rem;
    }
    .legal_why h2 {
        padding: 1rem;
    }
    .legal_why_img {
        display: none;
    }
    .legal_check_box {
        align-items: center;
        flex-basis: 50%;
        padding: 1rem 0;
    }
    .legal_contact {
        padding: 1rem;
    }
}
@media (max-width: 480px) {
    .legal_why {
        padding-bottom: 0;
    }
    .legal_check_box {
        align-items: center;
        flex-basis: 100%;
    }
    .legal_integrations_imgs {
        padding: 0;
    }

    .legal_integrations_imgs img {
        width: 33%;
        margin-right: 0;
    }
    .atash_text {
        padding: 2rem 1rem;
        font-size: 1.2rem;
    }
}

.retail_hero {
    background: url(/static/media/retail.05c6ff98.jpg) center no-repeat;
    background-size: cover;
    width: 100%;
    height: 70vh;
}

.retail_hero .blue_overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
}

.retail_hero h1 {
    font-size: 4rem;
    color: #ffffff;
    text-align: center;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.6);
}

.retail_hero p {
    text-align: center;
    color: #ffffff;
    padding: 1rem;
}

.retail_main {
    padding-bottom: 3rem;
}

.retail_card {
    padding: 3rem;
    min-height: 30vh;
    width: 70%;
    color: var(--mainColor);
}

.retail_card h2 {
    text-align: center;
}
.retail_card p {
    text-align: center;
    font-size: 1.1rem;
}

.retail_card_img {
    flex: 1 1;
    background: url(/static/media/shopping.6f80252d.jpg) center no-repeat;
    background-size: cover;
}

.retail_card_text {
    background-color: #f8f8f8;
    line-height: 1.5rem;

    flex: 2 1;
}

.retail_checks {
    width: 100%;
    flex-wrap: wrap;
    padding-bottom: 2rem;
    /* border: violet 1px solid; */
    justify-content: center;
    padding-left: 20%;
}

.retail_check_box {
    /* align-items: center;
    justify-content: center; */
    flex-basis: 50%;
    padding-top: 1rem;
    /* border: 1px blue solid; */
}

.retail_check_box span {
    font-weight: 500;
}

.retail_check {
    color: tomato;
    /* border: 1px solid green; */
    padding-right: 1rem;
}

.features_modal {
    width: 60vw;
    flex-wrap: wrap;
    padding: 2rem;
}

.features_modal_bottom h1 {
    padding: 1rem 4rem;
    font-weight: 400;
}

.features_modal_item {
    padding-bottom: 1rem;
    flex-basis: 25%;
    color: var(--secColor);
}

.features_modal_item span {
    padding: 0.15rem 0 0 1rem;
    color: var(--mainColor);
}

.retail_integrations_modal {
    padding: 0 20%;
}

.retail_bottom {
    padding: 2rem 20%;
}

.retail_bottom_card {
    min-height: 30vh;
}

.retail_bottom_img {
    flex: 1 1;
    background: url(/static/media/business.4a97966b.jpg) center no-repeat;
    background-size: cover;
}

.retail_bottom_text {
    flex: 2 1;
    /* padding: 1rem 2rem; */
    color: var(--mainColor);
    background-color: #f8f8f8;
}

.retail_bottom_text h1 {
    text-align: center;
    font-weight: 400;
}

.retail_bottom_text h1 span {
    color: tomato;
    font-weight: 500;
}

.retail_bottom_text p {
    font-size: 1.1rem;
    line-height: 1.5rem;
    padding: 1rem;
}

.pad2_20 {
    padding: 2rem 20%;
}

.retail_contact {
    padding-bottom: 3rem;
}

@media (max-width: 1440px) {
    .retail_bottom {
        padding: 2rem 10%;
    }
    .retail_checks {
        padding-left: 10%;
    }
    .retail_integrations_modal {
        padding: 0 10%;
    }
    .retail_card {
        width: 80%;
    }
}
@media (max-width: 1024px) {
    .retail_hero h1 {
        font-size: 3rem;
    }
    .retail_checks {
        padding-left: 0;
    }
    .retail_integrations_modal {
        padding: 0 5%;
    }
    .retail_card {
        width: 90%;
    }
}
@media (max-width: 768px) {
    .retail_hero {
        height: 40vh;
    }
    .retail_hero h1 {
        font-size: 2.5rem;
    }

    .retail_hero .blue_overlay {
        justify-content: flex-end;
        padding-bottom: 2rem;
    }
    .retail_card {
        flex-direction: column;
        width: 90%;
    }
    /* .retail_card p {
        text-align: center;
        font-size: 1.1rem;
    } */
    .retail_card_img {
        min-height: 30vh;
    }
    .retail_check_box {
        flex-basis: 50%;
    }
    .retail_checks {
        padding-left: 5%;
    }
    .retail_bottom_text h1 {
        font-size: 1.5rem;
    }
    .retail_bottom_text p {
        line-height: 1rem;
        line-height: 1.4rem;
    }
    .retail_bottom_card {
        flex-direction: column;
    }
    .retail_bottom_img {
        min-height: 30vh;
    }
}
@media (max-width: 480px) {
    .retail_check_box {
        flex-basis: 100%;
        padding: 0;
    }
    .retail_checks {
        padding-left: 20%;
    }
    .retail_bottom_text h1 {
        font-size: 1.5rem;
    }
    .retail_bottom_text p {
        font-size: 1rem;
        line-height: 1.2rem;
    }
    .retail_card {
        width: 100%;
    }
}

.splash {
  /* height: calc(100vh - 100px); */
  background-color: #eeeeee;
  height: calc(100vh - 185px);
  width: 100%;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  /* padding: 10%; */
  padding-top: 20vh;
}

.splash h1 {
  color: var(--newColor);
  font-size: 2rem;
  line-height: 3rem;
  padding-bottom: 1rem;
  text-align: center;
}

.splash-card {
  width: 47vw;
  height: 50vh;
  display: flex;
  justify-content: space-between;
  border-radius: 1rem;
  background-color: #ffffff;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
  background: url(/static/media/hotelRoomPhoneSM.7abe4251.jpg) no-repeat;
  background-size: cover;
  background-position: 40% 50%;
}

.splash-card-text {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  align-items: center;
  flex: 1 1;
  color: var(--newColor);
  padding: 2rem;
  font-size: 1.2rem;
  text-shadow: 10px 10px 10px #ffffff;
  text-align: center;
}

.splash-card-text form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  width: 60%;
}

/* .splash-card-img { */
/* flex: 1; */
/* border: 1px green solid; */

/* border-radius: 0 1rem 1rem 0; */
/* } */

/* .splash-card img {
    height: 100%;
    object-fit: contain;
    border: 2px blue solid;
} */

.splash-card-overlay {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  background-image: linear-gradient(to right, #ffffff 30%, transparent);
  border-radius: 1rem;
}

.splash-card-text form input {
  line-height: 2rem;
  width: 80%;
  min-width: 22ch;
  border-radius: 5px;
  border: 1px solid grey;
  padding-left: 1ch;
}

@media (max-width: 1440px) {
  .splash-card {
    width: 60vw;
    height: 50vh;
  }
}
@media (max-width: 1024px) {
  .splash-card {
    width: 70vw;
    height: 50vh;
  }
  .splash-card-text form input {
    width: 80%;
  }
}
@media (max-width: 768px) {
  .splash h1 {
    font-size: 2rem;
    line-height: 2.5rem;
  }
  .splash-card {
    width: 80vw;
    height: 50vh;
  }
}
@media (max-width: 480px) {
  .splash-card {
    width: 90vw;
    height: 45vh;
  }
  .splash-card-text form input {
    width: 100%;
  }
  .splash-card-text {
    font-size: 1rem;
  }
}

.privacy-container {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  padding: 7rem;
  width: 100%;
}
.privacy-container > p {
  padding: 1rem 0;
  justify-self: flex-start;
  width: 100%;
}

.privacy-container h1 {
  color: var(--newColor);
  padding-bottom: 2rem;
}

.text-pad {
  padding: 0.5rem 2rem;
}

.text-pad ul {
  padding: 0.3rem 2rem;
}
.text-pad ul li {
  list-style: lower-alpha;
  padding: 0.3rem 1rem;
}
.text-pad ul li ul li {
  list-style: lower-roman;
}
.text-pad span {
  display: block;
}
.text-pad .list-none {
  list-style: none;
}

.text-pad ul li p {
  padding: 0.3rem 0;
}

@media (max-width: 480px) {
  .privacy-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.corporate_hero {
    background: url(/static/media/corporate.7d89645d.jpg) center no-repeat;
    background-size: cover;
    width: 100%;
    height: 70vh;
    margin-bottom: 2rem;
}

.corporate_hero .blue_overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
}

.corporate_hero h1 {
    text-align: center;
    font-size: 4rem;
    color: #ffffff;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.6);
}

.corporate_hero p {
    padding: 1rem;
    text-align: center;
    color: #ffffff;
}

.corporate_main {
    padding: 1rem 10%;
}

.corporate_main .divider {
    padding: 1rem 0;
}

.corporate_main_card {
    flex-direction: row-reverse;
}
.corporate_main_card_img {
    background-color: #ffffff;
    flex: 1 1;
    /* flex-basis: 35%; */
    /* border: springgreen 1px solid; */
}

.corporate_main_card_text {
    /* background-color: #f8f8f8; */
    font-size: 1.4rem;
    text-align: center;
    color: var(--mainColor);
    padding: 2rem;
    flex: 2 1;
}

.corporate_main_card_text span {
    font-weight: 500;
}

.corporate_main_card_text p:last-of-type {
    font-size: 1.2rem;
    margin-top: 2rem;
    font-weight: 300;
}

.corporate_main_card_img img {
    background-color: #ffffff;
    /* object-position: center; */
    object-fit: contain;
    width: 100%;
    padding: 2rem;
}

.corporate_middle {
    padding: 2rem 0;
}

.corporate_info_card {
    flex: 1 1;
    color: var(--mainColor);
}

.corporate_card {
    min-height: 40vh;
}

.corporate_card_img {
    flex: 1 1;
    background: url(/static/media/employee.1569ba6a.jpg) no-repeat;
    background-position: 50%;
    background-size: cover;
}

.corporate_card_text {
    background-color: #f8f8f8;
    font-size: 1.1rem;
    line-height: 1.3rem;
    height: 100%;
}

.corporate_card_text h1 {
    text-align: center;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 2rem;
    padding-bottom: 2rem;
}

.corporate_checks {
    flex-wrap: wrap;
    justify-content: center;
}

.corporate_check_box {
    padding: 0.5rem;
    flex-basis: 50%;
    align-items: center;
}
.corporate_check {
    margin-right: 1rem;
    color: tomato;
}

@media (max-width: 1440px) {
    .corporate_main {
        padding: 1rem 10%;
    }
    .corporate_check_box {
        font-size: 1rem;
    }
}

@media (max-width: 1024px) {
    .corporate_hero h1 {
        font-size: 3rem;
    }
    .corporate_main_card_text {
        font-size: 1.2rem;
        padding: 2rem;
        flex: 2 1;
    }
    .corporate_check_box {
        flex-basis: 100%;
    }
}
@media (max-width: 768px) {
    .corporate_hero {
        height: 40vh;
    }
    .corporate_hero h1 {
        font-size: 2.5rem;
    }

    .corporate_hero .blue_overlay {
        justify-content: flex-end;
        padding-bottom: 2rem;
    }
    .corporate_main {
        padding: 1rem 10%;
    }
    .corporate_main_card {
        flex-direction: column;
    }
    .corporate_main_card_img {
        align-self: center;
        width: 60%;
    }
    .corporate_card {
        flex-direction: column-reverse;
    }
    .corporate_card_text h1 {
        font-size: 1.3rem;
        line-height: 1.5rem;
    }
    .corporate_check_box {
        flex-basis: 50%;
    }
    .corporate_card_img {
        min-height: 40vh;
    }
    .corporate_middle {
        padding: 0;
    }
    .corporate_main_card_text {
        font-size: 1.1rem;
    }
    .corporate_main_card_text p:last-of-type {
        font-size: 1rem;
    }
}
@media (max-width: 480px) {
    .corporate_main_card_text {
        font-size: 1rem;
        padding: 1rem;
    }

    .corporate_main_card_text p:last-of-type {
        font-size: 0.9rem;
    }

    .corporate_check_box {
        flex-basis: 100%;
        font-size: 0.9rem;
    }
}

.hospitality_hero {
    background: url(/static/media/hospitality.6df4659c.jpg) center no-repeat;
    background-size: cover;
    width: 100%;
    height: 70vh;
}

.hospitality_hero .blue_overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
}

.hospitality_hero h1 {
    text-align: center;
    font-size: 4rem;
    color: #ffffff;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.6);
}

.hospitality_hero p {
    text-align: center;
    color: #ffffff;
    padding: 1rem;
}

.hospitality_main {
    padding: 2rem 20%;
}

.hospitality_card {
    min-height: 30vh;
    color: var(--mainColor);
    text-align: center;
    padding: 2rem 0;
}

.sushi_card {
    min-height: 20vh;
    text-align: center;
    padding: 2rem 0;
}

.hospitality_card_img,
.sushi_card_img {
    flex: 1 1;
}

.hospitality_card_text {
    flex: 2 1;
    background-color: #f8f8f8;
}

.hospitality_card_text h2 span {
    color: tomato;
}

.hospitality_card_text p {
    padding-top: 2rem;
    font-size: 1.1rem;
    line-height: 1.3rem;
}

.sushi_card_text {
    flex: 2 1;
    justify-content: center;
}

.sushi_card_text p {
    font-size: 1.4rem;
    /* line-height: 1.3rem; */
    color: var(--mainColor);
}

.sushi_card_img {
    padding: 2rem;
    /* border: springgreen 1px solid; */
    /* flex-basis: 35%; */
}

.sushi_card_text p:last-of-type {
    padding-top: 2rem;
    font-weight: 300;
}

.hc1 {
    background: url(/static/media/coffee.b4eeef64.jpg) center no-repeat;
    background-size: cover;
}
.hc2 {
    background: url(/static/media/delivery.5c2ef10e.jpg) center no-repeat;
    background-size: cover;
}

.sushi {
    background: url(/static/media/Sushi.7e10906e.jpg) center no-repeat;
    background-size: contain;
    background-color: #ffffff;
}

@media (max-width: 1440px) {
    .hospitality_main {
        padding: 2rem 10%;
    }
}
@media (max-width: 1024px) {
    .hospitality_hero h1 {
        font-size: 3rem;
    }
}
@media (max-width: 768px) {
    .hospitality_hero {
        height: 40vh;
    }
    .hospitality_hero h1 {
        font-size: 2.5rem;
    }
    .hospitality_hero .blue_overlay {
        justify-content: flex-end;
        padding-bottom: 2rem;
    }
    .hospitality_main {
        padding: 2rem 5%;
    }
    .sushi_card {
        flex-direction: column;
        border: 10x red solid;
    }
    .sushi_card_text p {
        font-size: 1rem;
    }
    .sushi_card_img {
        padding: 4rem;
    }
    .hospitality_card {
        flex-direction: column-reverse;
        flex-wrap: wrap;
    }
    .hospitality_card_text {
        flex-basis: 100%;
    }
    .hospitality_card_img {
        flex-basis: 50%;
        min-height: 30vh;
    }
    .sushi_card {
        padding-bottom: 0;
    }
}
@media (max-width: 480px) {
}

.unsubscribe {
  background-color: #eeeeee;
  height: calc(100vh - 185px);
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 20vh;
}

.unsubscribe h1 {
  color: var(--newColor);
  font-size: 3rem;
  line-height: 3rem;
  padding-bottom: 1rem;
  text-align: center;
}

.unsubscribe-card {
  width: 47vw;
  height: 50vh;
  display: flex;
  justify-content: space-between;
  border-radius: 1rem;
  background-color: #ffffff;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.3);
  background: url(/static/media/phone.349f0f70.jpg) center no-repeat;
  background-size: contain;
  background-position: right;
}

.unsubscribe-card-text {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  color: var(--newColor);
  padding: 2rem;
  font-size: 1.2rem;
  text-align: center;
}

.unsubscribe-card-text .card-left {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
}

.unsubscribe-card-text img {
  height: 80px;
  width: 80px;
  margin-bottom: 1rem;
}

.unsubscribe-card-text .welcome-text {
  width: 60%;
}

.unsubscribe-card-text form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  width: 60%;
}

.unsubscribe-card-overlay {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  background-image: linear-gradient(to right, #ffffff 25%, transparent);
  border-radius: 1rem;
}

.unsubscribe-card-text form input {
  line-height: 2rem;
  width: 100%;
  min-width: 22ch;
  border-radius: 5px;
  border: 1px solid grey;
  padding-left: 1ch;
}

@media (max-width: 1440px) {
  .unsubscribe-card {
    width: 60vw;
    height: 50vh;
  }
}
@media (max-width: 1024px) {
  .unsubscribe h1 {
    font-size: 2rem;
  }
  .unsubscribe-card {
    width: 70vw;
    height: 50vh;
  }
  .unsubscribe-card-text form input {
    width: 80%;
  }
}
@media (max-width: 768px) {
  .unsubscribe-card {
    width: 80vw;
    height: 50vh;
  }
}
@media (max-width: 480px) {
  .unsubscribe-card {
    width: 90vw;
    height: 45vh;
  }
  .unsubscribe-card-text form input {
    width: 100%;
  }
}

