/* mobile */
/* .cont { 
    position: absolute;
    left: 50%;
    top: 50%;
    transform: Translate(-50%, -50%);
} */

/* web */
.loader-p {
  position: relative;
  margin-top: 40px;
  z-index: 155;
}
.cont {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 150;
}
.shredder {
  background-color: lightgray;
  color: var(--newColor);
  border-bottom: 6px solid var(--newColor);
  border-top: 5px solid var(--mainColor);
  width: 10rem;
  font-size: 1.2rem;
  padding: 10px 0px 10px 35px;
  border-radius: 0px 0px 10px 10px;
  box-shadow: 0px 0px 15px 1px rgba(22, 41, 102, 0.534);
  position: relative;
}

.shredder:after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: #c8d6e5;
  top: -3px;
  left: 0;
  position: absolute;
}

.loader {
  position: absolute;
  top: 20px;
  left: 34px;
}

.shredder .loader div {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: var(--newColor);
  position: absolute;
  transform-origin: -8px;
  animation: light 3s linear infinite;
}

.shredder .loader div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: 0s;
}

.shredder .loader div:nth-child(2) {
  transform: rotate(60deg);
  animation-delay: 0.5s;
}

.shredder .loader div:nth-child(3) {
  transform: rotate(120deg);
  animation-delay: 1s;
}

.shredder .loader div:nth-child(4) {
  transform: rotate(180deg);
  animation-delay: 1.5s;
}

.shredder .loader div:nth-child(5) {
  transform: rotate(240deg);
  animation-delay: 2s;
}

.shredder .loader div:nth-child(6) {
  transform: rotate(300deg);
  animation-delay: 2.5s;
}

@keyframes light {
  0% {
    background-color: var(--newColor);
  }
  40% {
    background-color: var(--newColor);
  }
  50% {
    background-color: white;
  }
  60% {
    background-color: var(--newColor);
  }
  100% {
    background-color: var(--newColor);
  }
}

.paper {
  width: 8rem;
  height: 6rem;
  box-shadow: 0px 0px 10px 1px rgba(55, 55, 55, 0.3);
  left: 0;
  right: 0;
  margin: auto;
  animation: paper 6s ease infinite;
  position: absolute;
  transform-origin: top;
  background-color: #fff;
}

/* .paper p {
  width: 100%;
  position: relative;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  transform: translateY(0px) rotate(180deg);
  animation: text 6s ease infinite;
  font-size: 1.1rem;
} */

.g-cont {
  margin: 0 auto;
  width: 8rem;
  text-align: center;
  position: absolute;
  top: 3.3rem;
  left: 0.8rem;
  right: 0;
  margin: auto;
  z-index: 10;
}

.garbage {
  height: 7rem;
  width: 6px;
  box-shadow: 0px 0px 10px 1px rgba(15, 26, 128, 0.3);
  display: inline-block;
  vertical-align: top;
  margin-right: 9px;
  animation: garbage 6s ease infinite;
  background-color: #fff;
}

@keyframes garbage {
  0% {
    height: 0px;
    transform: translateY(0px);
    opacity: 0;
  }
  20% {
    height: 0px;
    transform: translateY(0px);
    opacity: 1;
  }
  50% {
    height: 80px;
    transform: translateY(0px);
    opacity: 0.5;
  }
  70% {
    height: 100px;
    transform: translateY(0px);
    opacity: 0.2;
  }
  90.1% {
    height: 120px;
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    height: 0px;
    opacity: 0;
  }
}

@keyframes paper {
  0% {
    height: 160px;
    opacity: 0;
    transform: translateY(-100px) rotate(180deg);
  }
  20% {
    height: 160px;
    opacity: 1;
    transform: translateY(0px) rotate(180deg);
  }
  70% {
    height: 0px;
    opacity: 0.5;
    transform: translateY(0px) rotate(180deg);
  }
  70.1% {
    height: 0px;
    opacity: 0;
    transform: translateY(0px) rotate(180deg);
  }
  100% {
    height: 160px;
    opacity: 0;
    transform: translateY(0px) rotate(180deg);
  }
}

@keyframes text {
  0% {
    height: 160px;
    opacity: 0;
    transform: translateY(-100px) rotate(180deg);
  }
  20% {
    height: 160px;
    opacity: 1;
    transform: translateY(-0px) rotate(180deg);
  }
  70% {
    height: 0px;
    opacity: 1;
    transform: translateY(-10px) rotate(180deg);
  }
  70.1% {
    height: 0px;
    opacity: 0;
    transform: translateY(-50px) rotate(180deg);
  }
  100% {
    height: 160px;
    opacity: 0;
    transform: translateY(-180px) rotate(180deg);
  }
}
